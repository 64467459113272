import React from 'react'
import TagManager from 'react-gtm-module'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { CustomRoute } from '../routes'
import { Login, SignUp, SignUpConsumer, Gallery, PhotoInsert, PhotoEdit, Confirmation, RecoverPassword, ResetPassword, AdminPainel, EditProfile, StoreGallery, BuyPhoto, LandingConsumer, CartProduct, SalesPainel } from '../pages'
import { isAuthenticated } from "../api/auth";
import 'bootstrap/dist/css/bootstrap.min.css'

const tagManagerArgs = {
    gtmId: 'GTM-WK2Q7JP'
}

TagManager.initialize(tagManagerArgs)

function App() {
    const conditions = {
        public: "Public",
        private: "Private",
        user : "User",
        admin: "Admin",
        consumer: "Consumer",
        restrict: "Restrict"
    }

    return (
        <Router>
            <Switch>
                <Route exact path="/" component={isAuthenticated() ? Gallery : (window.location.pathname === "/" ? <div id="divCheckbox" style={{display: "none"}}>{window.location.href = window.location.pathname.concat("landing/index.html")}</div> : Login)} />
                <CustomRoute exact path="/login" condition={conditions.public} component={Login} />
                <CustomRoute exact path="/signup" condition={conditions.public} component={SignUp} />
                <CustomRoute exact path="/signup-consumer" condition={conditions.public} component={SignUpConsumer} />
                <CustomRoute exact path="/recover" condition={conditions.public} component={RecoverPassword} />
                <CustomRoute exact path="/resetpassword/:id" condition={conditions.public} component={ResetPassword} />
                <CustomRoute exact path="/confirmation/:id" condition={conditions.public} component={Confirmation} />
                <CustomRoute exact path="/photo" condition={conditions.restrict} component={PhotoInsert} />
                <CustomRoute exact path="/photo/:id" condition={conditions.private} component={PhotoEdit} />
                <CustomRoute exact path="/buy-photo/:id" condition={conditions.consumer} component={BuyPhoto} />
                <CustomRoute exact path="/users" condition={conditions.admin} component={AdminPainel} />
                <CustomRoute exact path="/edit-profile" condition={conditions.private} component={EditProfile} />
                <CustomRoute exact path="/store" condition={conditions.private} component={StoreGallery} />
                <CustomRoute exact path="/landing-consumer" condition={conditions.public} component={LandingConsumer} />
                <CustomRoute exact path="/cart" condition={conditions.private} component={CartProduct} />
               <CustomRoute exact path="/my-sales" condition={conditions.restrict} component={SalesPainel} />
            </Switch>
        </Router>
    )
}

export default App
