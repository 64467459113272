import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    buttons: {
        margin: theme.spacing(1),
        backgroundColor: "#ff5100",
        color: "white",
        '&:hover': {
            backgroundColor: '#ff5100',
            color: 'white',
        },
    }
}));

export default function LoginDialog() {
    const classes = useStyles();

    const component = (
        <div>            
            <Button size="small" className={classes.buttons} variant="contained">
                <Link onClick={() => window.location.href = "/fotografo/index.html"} style={{color: "#FFF"}}>
                    Fotógrafo
                </Link>
            </Button>
        </div>
    )

    return component
}

