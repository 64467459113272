import React from 'react'
import LazyLoad from 'react-lazyload';
import Grid from '@material-ui/core/Grid';
import 'react-table/react-table.css'
import Card from "@material-ui/core/Card";
import { Wrapper, Image } from ".";
import Divider from "@material-ui/core/Divider"
import {Typography} from "@material-ui/core";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    margin: {
        position: 'sticky',
        top: theme.spacing(1),
        marginLeft: 'auto',
        width: "36px",
        height: "36px",
        background: 'transparent'
    }
}));

export default function StoreGallery({photos, filterLogic, history, toggleRaised, raised, addToCart, removeFromCart, myCartIds, type}) {
    const classes = useStyles();
    const component = (
        
        <Wrapper>
            <Grid container spacing={4} style={{width: "100%"}}>
            { (photos && photos.photos) ?
                photos.photos.filter(photo => filterLogic(photo)).map(photo => (
                        <Grid style={{display: "flex", justifyContent: "center"}} item xs={12} sm={6} md={4}>
                            <Card style={{width: "520px", height: "520px", display: "flex", flexDirection: "column", alignItems: "center"}}
                                onMouseOver={() => toggleRaised(photo)}
                                onMouseOut={() => toggleRaised(photo)}
                                raised={raised.find(x => x._id === photo._id).raise}>

                                {type === 'Consumer' ? (
                                    !myCartIds.includes(photo._id) ?
                                    <Fab onClick={() => addToCart({_id: photo._id})} className={classes.margin} variant="round" disableElevation>
                                        <AddIcon />
                                    </Fab>
                                    : 
                                    <Fab onClick={() => removeFromCart({_id: photo._id})} className={classes.margin} variant="round" disableElevation>
                                        <RemoveIcon />
                                    </Fab>
                                    
                                ) : ''}

                                <LazyLoad height={200} offset={100} once>
                                    <Image onClick={() => history.push('/buy-photo/' + photo._id)} src={photo.watermarkImage.url}/>
                                </LazyLoad>
                                <Divider variant="middle" style={{marginBottom: "16px", width: "80%"}}/>
                                <Typography variant="body1" >
                                    {photo.title ? photo.title : 'Sem Título'}
                                </Typography>
                            </Card>
                        </Grid>
                    )
                )
                : ''
            }
            </Grid>
        </Wrapper>
    )
    
    return component;
}