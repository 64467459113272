import axios from 'axios'
import { getToken } from './auth';

// const URL = process.env.REACT_APP_SERVER_URL
// const URL = 'http://localhost:8080'
const URL = 'https://uinphoto-stag.lincky.com'

const api = axios.create({
    baseURL: URL + '/api',
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    }
})

api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export const login = payload => api.post(`/signin`, payload)
export const signUp = payload => api.post(`/signup`, payload)
export const recoverPassword = payload => api.post(`/recover`, payload)
export const resetPassword = (id, payload) => api.post(`/reset/${id}`, payload)
export const confirmation = id => api.get(`/confirmation/${id}`)
export const typeUser = id => api.get(`/typeuser/${id}`)
export const getUsers = () => api.get(`/users`)
export const confirmPayment = payload => api.post(`/confirm-payment`, payload)
export const confirmCreditPayment = payload => api.post(`/confirm-credit-payment`, payload)
export const editProfile = payload => api.post(`/edit-profile`, payload)
export const hasBankAccount = payload => api.get(`/has-bank-account`)
export const insertPhoto = payload => api.post(`/photo`, payload)
export const updatePhoto = payload => api.post(`/photo/update`, payload)
export const approvePhoto = payload => api.post(`/photo/approve`, payload)
export const disapprovePhoto = payload => api.post(`/photo/disapprove`, payload)
export const removePhoto = payload => api.post(`/photo/delete`, payload)
export const getMyGallery = () => api.get(`/gallery`)
export const getCategories = () => api.get(`/categories`)
export const updateCategory = payload => api.post(`/category/update`, payload)
export const storeGallery = () => api.get(`/store`)
export const updateMovieById = (id, payload) => api.put(`/movie/${id}`, payload)
export const deleteMovieById = id => api.delete(`/movie/${id}`)
export const getMovieById = id => api.get(`/movie/${id}`)
export const buyPhoto = payload => api.post(`/buy-photo`, payload)
export const landingConsumer = () => api.get(`/landing-consumer`)
export const addToCart = payload => api.post(`/add-to-cart`, payload)
export const removeFromCart = payload => api.post(`/remove-from-cart`, payload)
export const getMyCart = () => api.get(`/get-cart`)
export const getMySales = payload => api.get(`/get-sales`, payload)
export const loadPacks = () => api.get(`/packs`)
export const loadMyCredits = () => api.get(`/my-credits`)

const apis = {
    login,
    signUp,
    recoverPassword,
    resetPassword,
    confirmation,
    typeUser,
    getUsers,
    confirmPayment,
    confirmCreditPayment,
    editProfile,
    hasBankAccount,
    insertPhoto,
    updatePhoto,
    approvePhoto,
    disapprovePhoto,
    removePhoto,
    getMyGallery,
    getCategories,
    updateCategory,
    storeGallery,
    updateMovieById,
    deleteMovieById,
    getMovieById,
    buyPhoto,
    landingConsumer,
    addToCart,
    removeFromCart,
    getMyCart,
    getMySales,
    loadPacks,
    loadMyCredits
}

export default apis
