import 'react-table/react-table.css'
import React from 'react'
import {CardContent, Dialog, DialogTitle, Typography} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(1),
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    button: {
        marginTop: theme.spacing(1),
        width: '100%'
    },
    title: {
      color: 'black'
    }
}));

export default function CreditDialog({open, setOpenDialog, packs, handlePayment}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpenDialog(false);
  }

  const CreditCard = ({ id, quantity, unitPrice }) => {
    let originalValue = 150 * 100;
    let numDiscount = (Number((originalValue - unitPrice) / originalValue) * 100).toFixed(0);
    return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h5">{quantity} Imagens</Typography>
        <Typography color="textSecondary"> 
          <s>R$ {quantity * (originalValue/100).toFixed(2)}</s> por R$ {(quantity * (unitPrice / 100)).toFixed(2)} | -{numDiscount}%
        </Typography>
        <Button size="small" variant="contained" color="primary" className={classes.button} onClick={() => handlePayment(id, quantity * unitPrice)}>Comprar</Button>
      </CardContent>
    </Card>
    )
  }

    return (
      <Dialog open={open} onClose={handleClose} maxWidth='xs'>
        <DialogContent>
          <DialogTitle className={classes.title}>Selecione o Pacote de Créditos</DialogTitle>
          {packs.map(pack => <CreditCard id={pack._id} quantity={pack.quantity} unitPrice={pack.unitPrice}/>)}
          <Typography gutterBottom>
            As imagens poderão ser baixadas em até {"(1)"} ano após a efetivação da compra.
          </Typography>
        </DialogContent>
      </Dialog>
    );
}