import 'react-table/react-table.css'
import React from 'react'
import Grid from '@material-ui/core/Grid';
import { Wrapper } from ".";
import {Typography} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginLeft: "36px",
        padding: theme.spacing(2),
        marginRight: '36px',
        width: "100%",
    },
    card: {
        margin: 'auto',
        marginTop: theme.spacing(2),
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
        padding: theme.spacing(1),
    }
}));

export default function SalesInformation({monthDeposit, yearDeposit, gridPhotos, quantityAllPhotos, qunatityMonthPhotos, quantityPhotosPeriod, periodSelected, totalAmount, monthAmount, periodAmount}) {
    const dateDeposit = monthDeposit + "/" + yearDeposit
    const photographerCommission = 30
    const classes = useStyles();

        const itemInformation = (text, value) => {
        return (
            <div>
                <Typography gutterBottom variant="h9">
                    <strong>{text}</strong>
                </Typography><br />
                <Typography gutterBottom variant="h9">
                    {value}
                </Typography><br /><br />
            </div>
        )
    }

    const itemSummary = (text, value) => {
        return (
            <Grid item xs={12} sm={4}>
                {itemInformation(text, value)}
            </Grid>
        )
    }

    const component = (
        <Wrapper>
            <paper className={classes.paper}>
                <Grid container direction="row" style={{width: "100%"}} justifyContent="space-between" alignItems="flex-start" flexDirection="row-reverse" >
                    <Grid container direction="column" style={{backgroundColor: 'white'}} item xs={12} sm={10}>
                        <span style={{color: '#ff5100', fontSize: '24px'}}><strong>Resumo</strong></span>
                        <br /><br />
                        <Grid container item direction="row">
                            {itemSummary('Total de vendas', String(quantityAllPhotos))}
                            {itemSummary('Valor de vendas', "R$ " + String((totalAmount/100).toFixed(2)))}
                            {itemSummary('Comissão Fotógrafo', "R$ "  + String(((totalAmount/100) * (photographerCommission/100)).toFixed(2)))}
                        </Grid>
                        <Grid container item direction="row">
                            {itemSummary('Total de vendas do mês', String(qunatityMonthPhotos))}
                            {itemSummary('Valor de vendas do mês', "R$ " + String((monthAmount/100).toFixed(2)))}
                            {itemSummary('Comissão Fotógrafo do mês', "R$ "  + String(((monthAmount/100) * (photographerCommission/100)).toFixed(2)))}
                        </Grid>
                        <Grid container item direction="row">
                            {itemSummary('Total de vendas do período selecionado', periodSelected === 'period' ? String(quantityPhotosPeriod) : 'nenhum período selecionado')}
                            {itemSummary('Valor de vendas do período selecionado', periodSelected === 'period' ? "R$ " + String((periodAmount/100).toFixed(2)) : 'nenhum período selecionado')}
                            {itemSummary('Comissão Fotógrafo do período selecionado', periodSelected === 'period' ? "R$ " + String((periodAmount/100 * (photographerCommission/100)).toFixed(2)) : 'nenhum período selecionado')}
                        </Grid>
                    </Grid>
                    <Grid style={{backgroundColor: '#dcdcdc'}} item xs={12} sm={2}>
                        <Grid container spacing={4} direction="column" style={{width: "100%"}}>
                            <Grid item className={classes.card}>
                                <span style={{color: '#ff5100', fontSize: '24px'}}><strong>Pagamento</strong></span>
                                <br /><br />
                                {itemInformation('% Comissão:', photographerCommission + '%')}
                                {itemInformation('Data do próximo depósito', '10/' + String(dateDeposit))}
                                {itemInformation('Valor a receber:', "R$ "  + String(((monthAmount/100) * (photographerCommission/100)).toFixed(2)))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br /><br />
                <Grid container direction="row" style={{width: "100%"}} justifyContent="space-between" alignItems="flex-start" flexDirection="row-reverse" >
                    <Grid container direction="column" style={{backgroundColor: 'white'}} item xs={12} sm={10}>
                        <span style={{color: '#ff5100', fontSize: '24px'}}><strong>Informações das vendas</strong></span>
                        <br />
                        {gridPhotos}
                    </Grid>
                </Grid>
            </paper>
            
        </Wrapper>
    )
    
    return component;
}