import React, { Component } from "react";
// import ImageUploading from "react-images-uploading";
import api from "../api";
// import Chip from '@material-ui/core/Chip';
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { NavBar } from "../components";
import CircularProgress from "@material-ui/core/CircularProgress";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Chip from "@material-ui/core/Chip";
import Check from "@material-ui/icons/Check";
import AddCircle from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { Checkbox, Input, ListItemText } from "@material-ui/core";

const StyledChip = styled(Chip)`
  margin: 4px;
`;

const TagWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
  margin: 0;
`;

const TextArea = styled.textarea.attrs({
  className: "form-control",
})`
  margin-bottom: 24px;
`;

const InputText = styled.input.attrs({
  className: "form-control",
})`
  margin-bottom: 24px;
`;

const Wrapper = styled.div.attrs({
  className: "form-group",
})`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  max-width: 90vw;
`;
const StyledStep = styled(Step)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const PRECO = 150 * 100;
const reasons = [
  "Falta de termo de autorização de uso de imagem",
  "Qualidade da imagem",
  "Foto duplicada",
  "Conteúdo da imagem vs UinStock",
  "Falta de descrição adequada ou tags",
];

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="R$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

class PhotoEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: "",
      photos: "",
      photo: "",
      doc: "",
      newDoc: "",
      data: [],
      title: "",
      description: "",
      tag: "",
      step: 0,
      steps: 3,
      error: "",
      type: "User",
      isLoading: false,
      price: PRECO,
      openDialogDisapprove: false,
      openDialogApprove: false,
      reason: "",
      reasonUser: "",
      category: [],
      categories: [],
    };
  }

  componentDidMount = async () => {
    await api.getMyGallery().then((response) => {
      const photo = Array(
        response.data.photos.photos.find(
          (x) => x._id === this.props.match.params.id
        )
      );
      this.setState({
        _id: photo[0]._id,
        title: photo[0].title ? photo[0].title : "",
        description: photo[0].description ? photo[0].description : "",
        doc: photo[0].declaration,
        category: photo[0].category,
        photos: photo,
        data: photo[0].tags,
        price: photo[0].price ? photo[0].price : "",
        type: response.data.type,
        reasonUser: photo[0].reason ? photo[0].reason : "",
      });
    });

    await api.getCategories().then((response) => {
      this.setState({
        categories: response.data.categories,
      });
    });

    this.setState({ isLoading: false });
  };

  onChangePhoto = (photo) => {
    this.setState({
      photo: photo[0] && photo,
    });
  };

  onChangeDoc = (doc) => {
    this.setState({
      doc: doc[0] && doc,
    });
  };

  handleListItemClick = (event) => {
    this.setState({
      reason: event.target.value,
    });
  };

  handleListCategories = (event) => {
    this.setState({
      category: event.target.value,
    });
  };

  handleClickOpenDisapprove = () => {
    this.setState({
      reason: "",
      openDialogDisapprove: true,
    });
  };

  handleClickOpenApprove = () => {
    this.setState({
      reason: "",
      openDialogApprove: true,
    });
  };

  handleClose = () => {
    this.setState({
      reason: "",
      openDialogDisapprove: false,
      openDialogApprove: false,
    });
  };

  handleSendReasonDisapprove = () => {
    this.handleClickOpenDisapprove();
  };

  handleSendReasonApprove = () => {
    this.handleClickOpenApprove();
  };

  handleUpdatePhoto = async () => {
    const id = this.state._id;
    const doc = this.state.doc;
    const newDoc = this.state.newDoc;
    const title = this.state.title;
    const description = this.state.description;
    const category = this.state.category;
    const tags = this.state.data;
    const price = this.state.price;
    const image = this.state.photos[0].image;

    let data = new FormData();
    data.append("_id", id);
    data.append("tags", JSON.stringify(tags));
    data.append("title", title);
    data.append("image", JSON.stringify(image));
    data.append("description", description);
    data.append("category", JSON.stringify(category));
    data.append("price", price);
    data.append("reason", "");

    if (newDoc) {
      data.append("doc", newDoc);
    } else {
      data.append("declaration", JSON.stringify(doc));
    }

    if (title === "") {
      window.alert("Preencha o título!");
      return;
    }

    if (description === "") {
      window.alert("Preencha a descrição!");
      return;
    }

    if (tags.length === 0) {
      window.alert("Preencha pelo menos uma tag!");
      return;
    }

    if (category.length === 0) {
      window.alert("Preencha pelo menos uma categoria em todas as fotos!");
      return;
    }

    this.setState({ isLoading: true });
    await api
      .updatePhoto(data)
      .then((res) => {
        window.alert(`Foto atualizada com sucesso!`);
        this.setState({
          photo: "",
          doc: "",
          newDoc: "",
          data: [],
          tag: "",
          title: "",
          description: "",
          isLoading: false,
          step: 0,
          steps: 3,
          error: "",
          price: "",
        });
        // window.location.reload();
        this.props.history.push("/");
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
          error: "Erro ao atualizar, tente novamente.",
        });
      });
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({
      doc: event.target.files[0] ? Array.from(event.target.files) : "",
    });
  };

  handleAdd = (key) => {
    if (this.state.tag && key === "Enter") {
      let array = this.state.data;
      array.push(this.state.tag);

      this.setState({
        data: array,
        tag: "",
      });
    }
  };

  removePhoto = async () => {
    this.setState({ isLoading: true });

    await api
      .removePhoto(this.state.photos[0])
      .then((res) => {
        window.alert(`Foto removida com sucesso!`);
        this.setState({
          photo: "",
          doc: "",
          data: [],
          title: "",
          description: "",
          tag: "",
          step: 0,
          steps: 3,
          error: "",
          isLoading: false,
          type: "User",
          price: "",
        });
        this.props.history.push("/");
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
          error: "Erro ao remover a foto, tente novamente.",
        });
      });
  };

  disapprove = async () => {
    this.handleClose();
    const id = this.state._id;
    const image = this.state.photos[0].image;
    const reason = this.state.reason;
    let data = {
      image: image,
      _id: id,
      reason: reason,
    };

    await api
      .disapprovePhoto(data)
      .then((res) => {
        window.alert(`Foto reprovada com sucesso`);
        this.setState({
          photo: "",
          doc: "",
          data: [],
          title: "",
          description: "",
          tag: "",
          step: 0,
          steps: 3,
          error: "",
          isLoading: false,
          type: "User",
          price: "",
          reason: "",
        });
        window.location.reload();
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
          error: "Erro ao reprovar a foto, tente novamente.",
        });
      });
  };

  approve = async () => {
    const id = this.state._id;
    const image = this.state.photos[0].image;
    const reason = this.state.reason;
    let data = {
      image: image,
      _id: id,
      reason: reason,
    };

    await api
      .approvePhoto(data)
      .then((res) => {
        window.alert(`Foto Aprovada com Sucesso!`);
        this.setState({
          photo: "",
          doc: "",
          data: [],
          title: "",
          description: "",
          tag: "",
          step: 0,
          steps: 3,
          error: "",
          isLoading: false,
          type: "User",
          price: "",
          reason: "",
        });
        window.location.reload();
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
          error: "Erro ao aprovar a foto, tente novamente.",
        });
      });
  };

  toDataURL(url) {
    return fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Origin: window.location.origin,
      },
    })
      .then((response) => {
        console.log(response);
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }

  download = async (url) => {
    const a = document.createElement("a");
    a.href = await this.toDataURL(url);
    a.download = "";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  render() {
    const photo = this.state.photos[0];
    const handleDelete = (chipToDelete) => () => {
      const array = this.state.data;

      const index = array.indexOf(chipToDelete);
      if (index > -1) {
        array.splice(index, 1);
      }

      this.setState({
        data: array,
      });
    };

    const showStatus = (status) => {
      if (status === "Waiting") {
        return (
          <p style={{ color: "#2d28c6" }}>
            Esperando Revisão dos Administradores
          </p>
        );
      } else if (status === "Authorized") {
        return <p style={{ color: "#28c638" }}>Foto Aprovada</p>;
      } else if (status === "Unauthorized") {
        return <p style={{ color: "#c62828" }}>Foto Não Aprovada</p>;
      }
      return "";
    };
    const showReasonUser = (status) => {
      if (status === "Waiting") {
        return null;
      } else if (status === "Authorized") {
        return (
          <p style={{ color: "#28c638" }}>
            Mensagem dos Administradores: {this.state.reasonUser}
          </p>
        );
      } else if (status === "Unauthorized") {
        return (
          <p style={{ color: "#c62828" }}>
            Mensagem dos Administradores: {this.state.reasonUser}
          </p>
        );
      }
      return "";
    };

    return (
      <div>
        <NavBar />
        <br />
        {this.state.type === "Admin" ? (
          <div
            style={{
              marginBottom: "16px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Dialog
              open={this.state.openDialogDisapprove}
              onClose={this.handleClose}
            >
              <DialogTitle>
                <span style={{ color: "black" }}>Selecione o motivo</span>
              </DialogTitle>
              <FormControl
                variant="outlined"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "8px",
                  marginRight: "8px",
                  marginBottom: "8px",
                }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Motivo
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.reason}
                  onChange={this.handleListItemClick}
                  label="Motivo"
                >
                  {reasons.map((reason) => (
                    <MenuItem value={reason}>{reason}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <DialogActions>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ width: "128px" }}
                  onClick={this.handleClose}
                >
                  Cancelar
                </Button>
                <Button
                  style={{
                    width: "128px",
                    marginLeft: "16px",
                    backgroundColor: "#1cce1f",
                    color: "#ffffff",
                  }}
                  onClick={this.disapprove}
                >
                  Seguir
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.openDialogApprove}
              onClose={this.handleClose}
            >
              <DialogTitle>
                <span style={{ color: "black" }}>Observação</span>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Escreva a seguir alguma informação ao fotógrafo (motivo de
                  recusa, observações, etc)
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="reason"
                  fullWidth
                  variant="standard"
                  value={this.state.reason}
                  onChange={(e) => this.setState({ reason: e.target.value })}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ width: "128px" }}
                  onClick={this.handleClose}
                >
                  Cancelar
                </Button>
                <Button
                  style={{
                    width: "128px",
                    marginLeft: "16px",
                    backgroundColor: "#1cce1f",
                    color: "#ffffff",
                  }}
                  onClick={this.approve}
                >
                  Seguir
                </Button>
                {/* <Button onClick={this.disapprove}>Seguir</Button> */}
              </DialogActions>
            </Dialog>
            <Button
              component="label"
              variant="contained"
              color="secondary"
              style={{ width: "128px" }}
              onClick={this.handleSendReasonDisapprove}
            >
              Reprovar
            </Button>
            <Button
              variant="contained"
              style={{
                width: "128px",
                marginLeft: "16px",
                backgroundColor: "#1cce1f",
                color: "#ffffff",
              }}
              onClick={this.handleSendReasonApprove}
            >
              Aprovar
            </Button>
          </div>
        ) : (
          ""
        )}
        {(this.state.type === "Admin" || this.state.type === "User") &&
        photo ? (
          <div>
            <Stepper
              activeStep={0}
              orientation="vertical"
              style={{ paddingTop: "0px" }}
            >
              <StyledStep key={"upload image"}>
                <StepLabel StepIconComponent={Check}>Upload da Foto</StepLabel>
                <StepContent style={{ padding: "0px", marginLeft: "0px" }}>
                  {
                    <Grid
                      style={{
                        width: "auto",
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                      container
                      key={photo}
                    >
                      <p style={{ color: "#c62828" }}>{this.state.error}</p>
                      {showStatus(photo.status)}
                      {showReasonUser(photo.status)}
                      <Image src={photo.image.url} />
                      <br />
                      <InputText
                        type="text"
                        placeholder="Título da Foto"
                        value={this.state.title}
                        onChange={(e) =>
                          this.setState({ title: e.target.value })
                        }
                        style={{
                          marginRight: "8px",
                          marginTop: "16px",
                          maxWidth: "512px",
                        }}
                      />
                      <TextArea
                        type="text"
                        placeholder="Descrição da Foto"
                        value={this.state.description}
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                        style={{
                          marginRight: "8px",
                          maxWidth: "512px",
                          height: "128px",
                        }}
                      />
                      {/* <InputText
                                                type="number"
                                                placeholder="Preço da foto R$0.00"
                                                value={this.state.price}
                                                onChange={e => this.setState({ price: e.target.value })}
                                                style={{marginRight: "8px", marginTop: "16px", maxWidth: "512px"}}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                            /> */}
                    </Grid>
                  }
                </StepContent>
              </StyledStep>
            </Stepper>
            <Stepper
              activeStep={0}
              orientation="vertical"
              style={{ paddingTop: "0px" }}
            >
              <StyledStep key={"upload doc"}>
                <StepLabel StepIconComponent={Check}>Autorização</StepLabel>
                <StepContent style={{ padding: "0px" }}>
                  <p style={{ color: "#c62828", textAlign: "center" }}>
                    {this.state.error}
                  </p>
                  <p style={{ color: "#2d28c6", textAlign: "center" }}>
                    {this.state.doc && this.state.doc[0]
                      ? this.state.doc[0].name
                      : ""}
                  </p>
                  {this.state.doc && this.state.doc.url ? (
                    <a
                      href={this.state.doc.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Acessar Autorização Atual
                    </a>
                  ) : (
                    ""
                  )}
                </StepContent>
                <br />
                {this.state.newDoc ? (
                  <p>
                    <br />
                    {this.state.newDoc.name}
                  </p>
                ) : (
                  ""
                )}
                <Wrapper>
                  <a href="https://uinphoto-api-staging.s3.sa-east-1.amazonaws.com/authorizations_model/Contrato+Parceria+Modelo+Menor+de+Idade.docx">
                    Baixar autorização de uso de imagem do(a) modelo menor de
                    idade
                  </a>
                  <a href="https://uinphoto-api-staging.s3.sa-east-1.amazonaws.com/authorizations_model/Contrato+Parceria+Modelo+Maior+de+Idade.docx">
                    Baixar autorização de uso de imagem do(a) modelo maior de
                    idade
                  </a>
                  <br />
                  <div style={{ paddingTop: "8px" }}>
                    <Button
                      component="label"
                      variant="contained"
                      color="primary"
                    >
                      Atualizar Autorização
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) =>
                          this.setState({ newDoc: e.target.files[0] })
                        }
                      />
                    </Button>
                  </div>
                </Wrapper>
              </StyledStep>
            </Stepper>
            <Stepper
              activeStep={0}
              orientation="vertical"
              style={{ paddingTop: "0px" }}
            >
              <StyledStep key={"category"}>
                <StepLabel StepIconComponent={Check}>Categorias *</StepLabel>
                <StepContent style={{ minWidth: "256px" }}>
                  <FormControl
                    variant="outlined"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginLeft: "8px",
                      marginRight: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    <Select
                      multiple
                      value={this.state.category}
                      onChange={this.handleListCategories}
                      input={<Input />}
                      renderValue={(selected) =>
                        this.state.categories
                          .filter((c) => this.state.category.includes(c._id))
                          .map((c) => c.title)
                          .join(", ")
                      }
                    >
                      {this.state.categories.map((category) => (
                        <MenuItem value={category._id}>
                          <Checkbox
                            checked={
                              this.state.category.indexOf(category._id) > -1
                            }
                          />
                          <ListItemText primary={category.title} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StepContent>
              </StyledStep>
            </Stepper>
            <Stepper
              activeStep={0}
              orientation="vertical"
              style={{ padding: "0px" }}
            >
              <StyledStep key={"adicionar tags"}>
                <StepLabel StepIconComponent={Check}>Tags</StepLabel>
                <StepContent style={{ padding: "0px" }}>
                  <p style={{ color: "#c62828", textAlign: "center" }}>
                    {this.state.error}
                  </p>
                  {photo.tags[0] ? (
                    <TagWrapper>
                      {photo.tags.map((data) => (
                        <li key={data.key}>
                          <StyledChip
                            label={data}
                            onDelete={handleDelete(data)}
                          />
                        </li>
                      ))}
                    </TagWrapper>
                  ) : (
                    <p style={{ textAlign: "center" }}>
                      Sem tags por enquanto!
                    </p>
                  )}
                  <div
                    style={{
                      display: "flex",
                      height: "38px",
                      marginTop: "8px",
                      justifyContent: "center",
                    }}
                  >
                    <InputText
                      type="text"
                      placeholder="Adicione tags para a foto"
                      value={this.state.tag}
                      style={{ width: "256px", marginLeft: "48px" }}
                      onChange={(e) => this.setState({ tag: e.target.value })}
                      onKeyDown={(e) => this.handleAdd(e.key)}
                    />
                    <IconButton
                      onClick={() => this.handleAdd("Enter")}
                      variant="contained"
                      color="primary"
                    >
                      <AddCircle />
                    </IconButton>
                  </div>
                  <br />
                  <br />
                  <div
                    style={{
                      marginBottom: "16px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      component="label"
                      variant="contained"
                      color="secondary"
                      style={{ width: "128px" }}
                      onClick={this.removePhoto}
                    >
                      Excluir
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: "128px", marginLeft: "16px" }}
                      onClick={this.handleUpdatePhoto}
                    >
                      Atualizar
                    </Button>
                  </div>
                  {this.state.isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <br />
                      <CircularProgress />
                    </div>
                  ) : (
                    ""
                  )}
                </StepContent>
              </StyledStep>
            </Stepper>
          </div>
        ) : photo && this.props.match.params.id !== photo.owner ? (
          <div>
            <Stepper
              activeStep={0}
              orientation="vertical"
              style={{ paddingTop: "0px" }}
            >
              <StyledStep key={"upload image"}>
                <StepContent style={{ padding: "0px", marginLeft: "0px" }}>
                  {
                    <Grid
                      style={{
                        width: "auto",
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                      container
                      key={photo}
                    >
                      <p style={{ color: "#c62828" }}>{this.state.error}</p>
                      <Image src={photo.image.url} />
                      <br />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>Título da foto:</strong>
                      </div>
                      <div>{this.state.title}</div>
                      <br></br>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>Descrição da foto:</strong>
                      </div>
                      <div>{this.state.description}</div>
                      <br></br>
                      <Button
                        component="label"
                        variant="contained"
                        onClick={(e) => this.download(photo.image.url)}
                        color="primary"
                      >
                        {/* <a href={photo.image.url + "?force=true"} id="download" download target="_blank" style={{color: "#FFF"}}> */}
                        Baixar Foto
                        {/* </a> */}
                      </Button>
                    </Grid>
                  }
                </StepContent>
              </StyledStep>
            </Stepper>
          </div>
        ) : this.state.isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            Nenhuma foto em sua galeria
          </div>
        )}
      </div>
    );
  }
}

export default PhotoEdit;
