export const TOKEN_KEY = "@uinstock";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = token => {
    localStorage.setItem(TOKEN_KEY, token);
    window.location.href = "/store";
};
export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    window.location.href = "https://uinstock.net";
};
