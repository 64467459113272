import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from "../api";
import { login } from "../api/auth";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
        margin: theme.spacing(1),
        },
    },
    error: {
        color: "red",
        fontSize: "12px"
    },
}));

const InputText = styled.input.attrs({
    className: 'form-control',
})`
    margin-bottom: 16px;
`

export default function DialogLoginAndSignup({openButton, handleClose, urlLogin, urlSignup}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(openButton);
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setemail] = React.useState(null);
    const [password, setpassword] = React.useState(null);
    const [name, setname] = React.useState(null);
    const [phone, setphone] = React.useState(null);

    const handleClickOpentoLogin = () => {
        setErrorMessage('') 
        setOpen("login");
    };
    
    const handleClickOpentoSignup = () => {
        setErrorMessage('') 
        setOpen("signup");
    };

    const handleClickOpentoRecoverPassword = () => {
        setErrorMessage('') 
        setOpen("recoverpassword");
    };

    const handleLogin = async () => {        
        const payload = {
            login: email,
            password: password
        }

        if (!email || !password) {
            setErrorMessage('Preencha todos os dados para logar');
        } else {
            try {
                await api.login(payload).then(res => {
                    login(res.data.token);
                    window.location.href = urlLogin; 
                })
            } catch (err) {
                setErrorMessage('Ocorreu um erro ao logar sua conta');
            }
        }
    };

    const handleSignUpConsumer = async () => {
        const payload = {
            name: name,
            email: email,
            phone: phone,
            password: password,
            type: "Consumer"
        }

        if (!name || !email || !phone || !password) {
            setErrorMessage('Preencha todos os dados para se cadastrar');
        } else {
            await api.signUp(payload).then(res => {
                window.alert(`Um email de confirmação foi enviado para seu email! Verifique o spam caso não encontre o email na caixa de entrada.`)
            })
            .then(async user => {
                const data = {
                    login: email,
                    password: password
                }
                try {
                    await api.login(data).then(res => {
                        login(res.data.token);
                        window.location.href = urlSignup; 
                    })
                } catch (err) {
                    setErrorMessage('Ocorreu um erro ao logar sua conta');
                }
            })
            .catch((err) => {
                setErrorMessage(err.response.data.error);
            })
        }
    };

    const handleRecoverPassword = async () => {
        const payload = {
            email: email
        }

        if (!email) {
            this.setState({ error: "Preencha todos os dados para recuperar sua senha" });
        } else {
            await api.recoverPassword(payload).then(res => {
                window.alert(res.data.message);
                window.location.reload();
            }).catch((err) => {
                this.setState({ error: err.response.data.error });
            })
        }
    };


    const component = (
        <div className={classes.root}>
            <Dialog open={open === "login"} onClose={handleClose} aria-labelledby="form-dialog-title" color="black" fullWidth maxWidth="xs">
                <DialogTitle disableTypography id="form-dialog-title" style={{ alignSelf: "center", color: 'black', fontSize: "24px" }}><b>Entrar</b></DialogTitle>
                <DialogContent>
                <DialogContentText style={{ alignSelf: "center", color: 'black', fontSize: "16px" }}>
                    Bem vindo ao UinStock!
                </DialogContentText>
                <InputText
                    type="email"
                    placeholder="Endereço de e-mail"
                    onChange={e => setemail(e.target.value) }
                />
                <InputText
                    type="password"
                    placeholder="Senha"
                    onChange={e => setpassword(e.target.value) }
                /> 
                {errorMessage && (
                        <p className={classes.error}> {errorMessage} </p>
                    )}
            </DialogContent>
            <DialogContentText align="center" style={{ alignSelf: "center", color: 'black', fontSize: "12px" }}>
                Não tenho cadastro ainda:   <Link onClick={handleClickOpentoSignup}>Cadastrar</Link>
            </DialogContentText>
            <DialogContentText align="center" style={{ alignSelf: "center", color: 'black', fontSize: "12px" }}>
                Esqueci minha senha:   <Link onClick={handleClickOpentoRecoverPassword} >Recuperar</Link>
            </DialogContentText>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleLogin} color="primary">
                    Entrar
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                    open={open === "signup"}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    color="black" 
                    fullWidth
                    maxWidth="xs"
                >
                    <DialogTitle disableTypography id="form-dialog-title" style={{ alignSelf: "center", color: 'black', fontSize: "24px" }}><b>Cadastro</b></DialogTitle>
                    <DialogContent>
                    <DialogContentText style={{ alignSelf: "center", color: 'black', fontSize: "16px" }}>
                        Faça o cadastro para comprar fotos.
                    </DialogContentText>
                    <InputText
                        type="text"
                        placeholder="Nome"
                        onChange={e => setname(e.target.value) }
                    />
                    <InputText
                        type="email"
                        placeholder="Endereço de e-mail"
                        onChange={e => setemail(e.target.value) }
                    />
                    <InputText
                        type="text"
                        placeholder="Telefone"
                        onChange={e => setphone(e.target.value) }
                    />
                    <InputText
                        type="password"
                        placeholder="Senha"
                        onChange={e => setpassword(e.target.value) }
                    />     
                    {errorMessage && (
                        <p className={classes.error}> {errorMessage} </p>
                    )}
                    <DialogContentText align="center" style={{ alignSelf: "center", color: 'black', fontSize: "12px" }}>
                        Já tenho cadastro:   <Link onClick={handleClickOpentoLogin} >Entrar</Link>
                    </DialogContentText>
                </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSignUpConsumer} color="primary">
                        Cadastrar
                    </Button>
                    </DialogActions>
                </Dialog>
                
                <Dialog open={open === "recoverpassword"} onClose={handleClose} aria-labelledby="form-dialog-title" color="black" fullWidth maxWidth="xs">
                    <DialogTitle disableTypography id="form-dialog-title" style={{ alignSelf: "center", color: 'black', fontSize: "24px" }}><b>Recuperar Senha</b></DialogTitle>
                    <DialogContent>
                    <InputText
                        type="email"
                        placeholder="Endereço de e-mail"
                        onChange={e => setemail(e.target.value) }
                    />
                    {errorMessage && (
                            <p className={classes.error}> {errorMessage} </p>
                        )}
                </DialogContent>
                <DialogContentText align="center" style={{ alignSelf: "center", color: 'black', fontSize: "12px" }}>
                        Já tenho cadastro:   <Link onClick={handleClickOpentoLogin} >Entrar</Link>
                    </DialogContentText>
                <DialogContentText align="center" style={{ alignSelf: "center", color: 'black', fontSize: "12px" }}>
                    Não tenho cadastro ainda:   <Link onClick={handleClickOpentoSignup}>Cadastrar</Link>
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleRecoverPassword} color="primary">
                        Recuperar
                    </Button>
                    </DialogActions>
                </Dialog>
        </div>
    )

    return component
}

