import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import api from "../api";
import Checkbox from '@material-ui/core/Checkbox';
import { NavBar } from '../components'

const Wrapper = styled.div.attrs({
    className: 'form-group',
})`
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    `

const InputText = styled.input.attrs({
    className: 'form-control',
})`
    margin-bottom: 24px;
`

const Button = styled.button.attrs({
    className: `btn btn-primary`,
})`
`

const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    
    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 3) return currentValue;
      if (cvLength < 8) return `(${currentValue.slice(0, 2)}) ${currentValue.slice(2)}`;
      return `(${currentValue.slice(0, 2)}) ${currentValue.slice(2, 7)}-${currentValue.slice(7, 11)}`;
    }
};

const input = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 12) return currentValue;
      else return previousValue
    }
};

class SignUp extends Component {
    state = {
        name: "",
        email: "",
        phone: "",
        displayPhone: "",
        password: "",
        checked: false,
        error: ""
    };

    handleSignUp = async () => {
        const { name, email, phone, password, checked } = this.state
        const payload = {
            name: name,
            email: email,
            phone: phone,
            password: password,
            checked: checked,
            type: "User"
        }

        if (!name || !email || !phone || !password ) {
            this.setState({ error: "Preencha todos os dados para se cadastrar" });
        } 
        else if (!checked){
            this.setState({ error: "Você precisa aceitar os termos de uso para se cadastrar" });
        } 
        else{
            await api.signUp(payload).then(res => {
                window.alert(`Um email de confirmação foi enviado para seu email!`)
                window.location.reload();
            }).catch((err) => {
                this.setState({ error: err.response.data.error });
            })
        }
    };

    handleLogin = () => {
        window.location.href = "/login"
    }

    handleChange = async (e) => {   
        this.setState({ displayPhone: normalizeInput(e.target.value , this.state.displayPhone)});
        this.setState({ phone: input(e.target.value, this.state.phone) });
    }; 

    render() {
        const { name, email, displayPhone, password, checked } = this.state

        return (
            <div>
                <NavBar />
                <br/>
                <Wrapper>
                    <p>Cadastrar</p>
                    <br/>
                    {this.state.error && <p style={{color: "red"}}>{this.state.error}</p>}
                    <InputText
                        type="text"
                        placeholder="Nome"
                        value={name}
                        onChange={e => this.setState({ name: e.target.value })}
                    />
                    <InputText
                        type="email"
                        placeholder="Endereço de e-mail"
                        value={email}
                        onChange={e => this.setState({ email: e.target.value })}
                    />
                    <InputText
                        type="text"
                        placeholder="Telefone"
                        value={displayPhone}
                        onChange={this.handleChange}
                    />
                    <InputText
                        type="password"
                        placeholder="Senha"
                        value={password}
                        onChange={e => this.setState({ password: e.target.value })}
                    />
                    
                    <div>
                        <Checkbox
                            checked={checked}
                            required
                            onChange={e => this.setState({ checked: e.target.checked })}
                            color="black"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        Li e aceito os <a href="https://uinphoto-api-staging.s3.sa-east-1.amazonaws.com/termos/20221031_Termos+e+Condi%C3%A7%C3%B5es+de+Uso+-+UinStock.docx.pdf" target="_blank" rel="noopener noreferrer">termos de uso</a> e <a href="https://uinphoto-api-staging.s3.sa-east-1.amazonaws.com/termos/20221031_Politica+de+Privacidade+-+UinStock.docx.pdf" target="_blank" rel="noopener noreferrer">política de privacidade</a>
                    </div>
                    <Button style={{marginTop: '16px', backgroundColor: "#ff5100", borderColor: "#ff5100"}} onClick={this.handleSignUp}>Cadastrar</Button>
                    <br />
                    <br />
                    Já tenho conta:
                    <Button style={{marginTop: '16px'}} onClick={this.handleLogin}>Fazer login</Button>
                </Wrapper>
            </div>
        );
    }
}

export default withRouter(SignUp);
