export const getUrlParam = (param) => {
  let params = new URLSearchParams(window.location.search);
  let search = params.get(param);
  if (search) return search;
  return "";
};

export const searchUrlString = () => {
  return getUrlParam("s");
};

export const categoryUrlString = () => {
  return getUrlParam("c");
};
