import React, { Component } from "react";
import api from "../api";

import "react-table/react-table.css";
import { NavBar, SearchBar, styles, MyGallery } from "../components";
import { isAuthenticated } from "../api/auth";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { searchUrlString, categoryUrlString } from "../utils/urlSearch";

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [],
      columns: [],
      isLoading: true,
      raised: [],
      search: searchUrlString(),
      type: "User",
      navBar: <NavBar />,
      navigation: 0,
      open: null,
      anchorEl: null,
      category: "",
      categories: [],
    };

    this.toggleRaised = this.toggleRaised.bind(this);
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true });

    await api.getMyGallery().then((response) => {
      this.setState({
        photos: response.data.photos,
        raised: response.data.photos.photos.map((photo) => ({
          _id: photo._id,
          raise: false,
        })),
        type: response.data.type,
      });
    });

    await api.getCategories().then((response) => {
      var cUrl = categoryUrlString();
      var selectedCategory = response.data.categories.find(
        (c) => c.title === cUrl
      );
      this.setState({
        categories: response.data.categories,
        category: selectedCategory ? selectedCategory._id : "",
      });

      this.setState({ isLoading: false });
    });

    this.setState({ isLoading: false });
  };

  toggleRaised = (photo) => {
    const raise = this.state.raised.findIndex((x) => x._id === photo._id);
    let raised = this.state.raised;
    raised[raise].raise = !raised[raise].raise;
    this.setState({ raised: raised });
  };

  render() {
    const { photos } = this.state;
    const { classes } = this.props;

    const search = (photo, tag) => {
      if (!photo) return false;
      const photoTags = [...photo.tags, photo.name];
      return photoTags
        .map((photoTag) =>
          this.state.category !== ""
            ? photoTag.toLowerCase().includes(tag.toLowerCase()) &&
              photo.category &&
              photo.category.includes(this.state.category)
            : photoTag.toLowerCase().includes(tag.toLowerCase())
        )
        .includes(true);
    };

    const filterLogic = (photo) => {
      return search(photo, this.state.search);
    };

    const onCapture = (e) => {
      this.setState({ search: e.target.value });
    };

    const onCategory = (e) => {
      this.setState({
        category: e.target.value,
      });
    };

    return (
      <div>
        <NavBar />
        <br />
        {isAuthenticated() && window.location.pathname === "/" ? (
          <SearchBar
            search={this.state.search}
            category={this.state.category}
            classes={classes}
            onCapture={onCapture}
            categories={this.state.categories}
            onCategory={onCategory}
          />
        ) : (
          ""
        )}

        <br />
        <MyGallery
          photos={photos}
          filterLogic={filterLogic}
          history={this.props.history}
          toggleRaised={this.toggleRaised}
          raised={this.state.raised}
        />
        {this.state.isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <br />
            <CircularProgress />
          </div>
        ) : (
          ""
        )}
        {!this.state.isLoading && !this.state.photos.photos[0] ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            Nenhuma foto em sua galeria
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Gallery);
