import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import api from "../api";
import { NavBar } from '../components'

const Wrapper = styled.div.attrs({
    className: 'form-group',
})`
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    `

const InputText = styled.input.attrs({
    className: 'form-control',
})`
    margin-bottom: 24px;
`

const Button = styled.button.attrs({
    className: `btn btn-primary`,
})`
`

class RecoverPassword extends Component {
    state = {
        email: "",
        error: ""
    };

    handle = async () => {
        const { email } = this.state
        const payload = {
            email: email
        }

        if (!email) {
            this.setState({ error: "Preencha todos os dados para recuperar sua senha" });
        } else {
            await api.recoverPassword(payload).then(res => {
                window.alert(res.data.message);
                window.location.reload();
            }).catch((err) => {
                this.setState({ error: err.response.data.error });
            })
        }
    };

    render() {
        const { email } = this.state

        return (
            <div>
                <NavBar />
                <br/>
                <Wrapper>
                    <p>Recuperar Senha</p>
                    <br/>
                    {this.state.error && <p>{this.state.error}</p>}
                    <InputText
                        type="text"
                        placeholder="Endereço de e-mail"
                        value={email}
                        onChange={e => this.setState({ email: e.target.value })}
                    />
                    <Button style={{backgroundColor: "#000"}} onClick={this.handle}>Redefinir Senha</Button>
                    <hr />
                    <Link to="/">Fazer login</Link>
                    <Link to="/signup">Cadastro</Link>
                </Wrapper>
            </div>
            
        );
    }
}

export default withRouter(RecoverPassword);
