import React, { Component } from "react";
import api from "../api";
import "react-table/react-table.css";
import { NavBar, styles, CartInformation } from "../components";
import withStyles from "@material-ui/core/styles/withStyles";

import { makeStyles } from "@material-ui/core/styles";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [],
      photosCart: [],
      columns: [],
      isLoading: true,
      raised: [],
      search: "",
      navBar: <NavBar />,
      navigation: 0,
      open: null,
      anchorEl: null,
      itemCount: 0,
      myCartIds: [],
      numberItemCart: 0,
    };

    this.toggleRaised = this.toggleRaised.bind(this);
  }

  useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      maxWidth: 500,
    },
    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  }));

  componentDidMount = async () => {
    await api.getMyCart().then((response) => {
      this.setState({
        photos: response.data.cart,
        raised: response.data.cart.photos.map((photo) => ({
          _id: photo._id,
          raise: false,
        })),
        numberItemCart: response.data.myCartIds.length,
      });
    });

    this.setState({ isLoading: false });
  };

  toggleRaised = (photo) => {
    const raise = this.state.raised.findIndex((x) => x._id === photo._id);
    let raised = this.state.raised;
    raised[raise].raise = !raised[raise].raise;
    this.setState({ raised: raised });
  };

  removeFromCart = async (photoId) => {
    var photos = this.state.photos.photos.filter(
      (item) => item._id !== photoId._id
    );

    this.setState({
      photos: { photos: photos },
      raised: photos.map((p) => ({
        _id: p._id,
        raise: false,
      })),
      myCartIds: this.state.myCartIds.filter((item) => item !== photoId._id),
      numberItemCart: this.state.numberItemCart - 1,
    });

    api
      .removeFromCart(photoId)
      .then(async (res) => {
        console.log(res);
      })
      .catch((e) => {
        this.setState({
          error: "Erro ao adicionar a foto no carrinho, tente novamente.",
        });
      });
  };

  render() {
    const { photos } = this.state;

    const search = (photo, tag) => {
        if (!photo) return false
        const photoTags = [...photo.tags, photo.title]
        return photoTags.map(photoTag => photoTag.toLowerCase().includes(tag.toLowerCase())).includes(true)
    }

    const filterLogic = (photo) => {
      switch (this.state.navigation) {
        case 1:
          photo = photo.status === "Authorized" ? photo : null;
          return this.state.search ? search(photo, this.state.search) : photo;
        case 2:
          photo = photo.status === "Waiting" ? photo : null;
          photo =
            photo && photo.declaration && photo.declaration.url ? photo : null;
          return this.state.search ? search(photo, this.state.search) : photo;
        case 3:
          photo = photo.status === "Unauthorized" ? photo : null;
          return this.state.search ? search(photo, this.state.search) : photo;
        case 4:
          photo = photo.declaration && photo.declaration.url ? null : photo;
          return this.state.search ? search(photo, this.state.search) : photo;
        default:
          return this.state.search ? search(photo, this.state.search) : photo;
      }
    };

    return (
      <div>
        <NavBar />
        <div className="text-center mt-5">
          <h1>Carrinho</h1>
          {this.state.isLoading ? (
            "Carregando..."
          ) : photos && photos.photos && photos.photos.length !== 0 ? (
            <p>Revise seus pedidos</p>
          ) : (
            "Seu carrinho está vazio"
          )}
        </div>
        <br></br>
        <CartInformation
          photos={photos}
          filterLogic={filterLogic}
          history={this.props.history}
          toggleRaised={this.toggleRaised}
          raised={this.state.raised}
          addToCart={this.addToCart}
          removeFromCart={this.removeFromCart}
          myCartIds={this.state.myCartIds}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Cart);
