import React from 'react';
import LazyLoad from 'react-lazyload';
import Grid from '@material-ui/core/Grid';
import 'react-table/react-table.css'
import Card from "@material-ui/core/Card";
import { Wrapper, Image } from ".";
import Divider from "@material-ui/core/Divider"
import {Typography} from "@material-ui/core";

export default function StoreGallery({photos, filterLogic, history, toggleRaised, raised}) {
    // const [open, setOpen] = React.useState(null);

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const dialogParams = (photoId) => ({
    //     handleClose: handleClose,
    //     urlLogin: '/buy-photo/' + photoId,
    //     urlSignup: '/buy-photo/' + photoId
    // })
    
    // const handleClickOpentoSignup = (photo) => {
    //     setOpen(<DialogLoginAndSignup {...dialogParams(photo._id)} openButton={"signup"} />);
    // };

    const component = (
        <Wrapper>
            <Grid container spacing={4} style={{width: "100%"}}>
            { (photos && photos.photos) ?
                // eslint-disable-next-line array-callback-return
                photos.photos.filter(photo => filterLogic(photo)).map(photo => (
                    <Grid style={{display: "flex", justifyContent: "center"}} item xs={12} sm={6}
                    md={4}>
                        <Card style={{width: "520px", height: "520px", display: "flex", flexDirection: "column", alignItems: "center"}}
                            onClick={() => { history.push('/signup-consumer') }}
                            onMouseOver={() => toggleRaised(photo)}
                            onMouseOut={() => toggleRaised(photo)}
                            raised={raised.find(x => x._id === photo._id).raise}>                                                
                            <LazyLoad height={200} offset={100} once>
                                <Image src={photo.watermarkImage.url}/>
                            </LazyLoad>
                            <Divider variant="middle" style={{marginBottom: "16px", width: "80%"}}/>
                            <Typography variant="body1">
                                {photo.title ? photo.title : 'Sem Título'}
                            </Typography>
                            <Typography variant="body2">
                                {photo.name ? photo.name : ''}
                            </Typography>
                        </Card>
                    </Grid>)
                )
                : ''
            }
            </Grid>
        </Wrapper>
    )
    
    return component;
}