import React from 'react'
import Button from '@material-ui/core/Button';


export default function CartPhotoButton({inCart, removeFromCart, addToCart}) {
    return (
        <div style={{marginBottom: "16px", display: 'flex', justifyContent: 'center'}}>
            {
                inCart ?
                    <Button component="label"
                            variant="contained"
                            color="secondary"
                            style={{width: "256px"}}
                            onClick={removeFromCart}>
                            Retirar do Carrinho
                    </Button>
                    : 
                    <Button component="label"
                        variant="contained"
                        color="secondary"
                        style={{width: "256px"}}
                        onClick={addToCart}>
                        Adicionar no Carrinho
                    </Button>
            }
        </div>
    )
}
