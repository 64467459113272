import React, { Component } from "react";
import api from "../api";
import { Typography } from "@material-ui/core";
import "react-table/react-table.css";
import { NavBar, SearchBar, styles, StoreGallery } from "../components";
import { isAuthenticated } from "../api/auth";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { searchUrlString, categoryUrlString } from "../utils/urlSearch";

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [],
      photosCart: [],
      columns: [],
      isLoading: true,
      raised: [],
      search: searchUrlString(),
      type: "User",
      navBar: <NavBar />,
      navigation: 0,
      open: null,
      anchorEl: null,
      itemCount: 0,
      myCartIds: [],
      numberItemCart: 0,
      cart: [],
      myCredits: undefined,
      category: "",
      categories: [],
    };

    this.toggleRaised = this.toggleRaised.bind(this);
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true });

    await api
      .storeGallery()
      .then(async (response) => {
        this.setState({
          photos: response.data.photos,
          raised: response.data.photos.photos.map((photo) => ({
            _id: photo._id,
            raise: false,
          })),
          type: response.data.type,
        });

        await api
          .getMyCart()
          .then((cart) => {
            this.setState({
              myCartIds: cart.data.myCartIds,
              numberItemCart: cart.data.myCartIds.length,
            });
          })
          .catch((e) => {
            this.setState({
              isLoading: false,
              error: "Erro, tente novamente.",
            });
          });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
          error: "Erro, tente novamente.",
        });
      });

    await api.getCategories().then((response) => {
      var cUrl = categoryUrlString();
      var selectedCategory = response.data.categories.find(
        (c) => c.title === cUrl
      );
      this.setState({
        categories: response.data.categories,
        category: selectedCategory ? selectedCategory._id : "",
      });

      this.setState({ isLoading: false });
    });

    this.setState({ isLoading: false });

    await api.loadMyCredits().then((response) => {
      this.setState({ myCredits: response.data.credits });
    });
  };

  toggleRaised = (photo) => {
    const raise = this.state.raised.findIndex((x) => x._id === photo._id);
    let raised = this.state.raised;
    raised[raise].raise = !raised[raise].raise;
    this.setState({ raised: raised });
  };

  addToCart = (photoId) => {
    this.setState({
      myCartIds: [...this.state.myCartIds, photoId._id],
    });

    api
      .addToCart(photoId)
      .then(async (res) => {
        console.log(res);
      })
      .catch((e) => {
        this.setState({
          error: "Erro ao adicionar a foto no carrinho, tente novamente.",
        });
      });
  };

  removeFromCart = async (photoId) => {
    this.setState({
      myCartIds: this.state.myCartIds.filter((item) => item !== photoId._id),
    });

    api
      .removeFromCart(photoId)
      .then(async (res) => {
        console.log(res);
      })
      .catch((e) => {
        this.setState({
          error: "Erro ao adicionar a foto no carrinho, tente novamente.",
        });
      });
  };

  render() {
    const { photos } = this.state;
    const { classes } = this.props;

    const search = (photo, tag) => {
      if (!photo) return false;
      const photoTags = [...photo.tags, photo.title];
      return photoTags
        .map((photoTag) =>
          this.state.category !== ""
            ? photoTag.toLowerCase().includes(tag.toLowerCase()) &&
              photo.category &&
              photo.category.includes(this.state.category)
            : photoTag.toLowerCase().includes(tag.toLowerCase())
        )
        .includes(true);
    };

    const onCapture = (e) => {
      this.setState({ search: e.target.value });
    };

    const onCategory = (e) => {
      this.setState({
        category: e.target.value,
      });
    };

    const filterLogic = (photo) => {
      switch (this.state.navigation) {
        case 1:
          photo = photo.status === "Authorized" ? photo : null;
          return search(photo, this.state.search);
        case 2:
          photo = photo.status === "Waiting" ? photo : null;
          photo =
            photo && photo.declaration && photo.declaration.url ? photo : null;
          return search(photo, this.state.search);
        case 3:
          photo = photo.status === "Unauthorized" ? photo : null;
          return search(photo, this.state.search);
        case 4:
          photo = photo.declaration && photo.declaration.url ? null : photo;
          return search(photo, this.state.search);
        default:
          return search(photo, this.state.search);
      }
    };

    return (
      <div>
        <NavBar />
        <br />
        {isAuthenticated() && window.location.pathname === "/store" ? (
          <div className={classes.search}>
            <SearchBar
              search={this.state.search}
              category={this.state.category}
              classes={classes}
              onCapture={onCapture}
              categories={this.state.categories}
              onCategory={onCategory}
            />
            {this.state.myCredits && this.state.myCredits > 0 ? (
              <Typography variant="h6" className={classes.typography}>
                Créditos Disponíveis: {this.state.myCredits}
              </Typography>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <br />
        <StoreGallery
          photos={photos}
          filterLogic={filterLogic}
          history={this.props.history}
          toggleRaised={this.toggleRaised}
          raised={this.state.raised}
          addToCart={this.addToCart}
          removeFromCart={this.removeFromCart}
          myCartIds={this.state.myCartIds}
          type={this.state.type}
        />
        {this.state.isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <br />
            <CircularProgress />
          </div>
        ) : (
          ""
        )}
        {!this.state.isLoading && !this.state.photos.photos[0] ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            Nenhuma foto disponível na loja.
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Gallery);
