import React, { Component } from 'react'
import api from '../api'
import styled from 'styled-components'
import Grid from "@material-ui/core/Grid";
import {NavBar} from "../components";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import { CircularProgress } from '@material-ui/core';
import { CartPhotoButton } from "../components";

const Image = styled.img`
    max-width: 90vw;
`
const StyledStep = styled(Step)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
class BuyPhoto extends Component {
    constructor(props) {
        super(props)

        this.state = {
            _id: '',
            photos: '',
            photo: '',
            doc: '',
            data: [
            ],
            title: '',
            description: '',
            tag: '',
            step: 0,
            steps: 3,
            error: '',
            type: 'User',
            isLoading: true,
            inCart: false
        }
    }

    componentDidMount = async () => {
        await api.storeGallery().then(response => {
            const photo = Array(response.data.photos.photos.find( x => x._id === this.props.match.params.id))
            this.setState({
                _id: photo[0]._id,
                title: photo[0].title ? photo[0].title :'',
                description: photo[0].description ? photo[0].description : '',
                doc: photo[0].declaration,
                photos: photo,
                data: photo[0].tags,
                type: response.data.type
            })
        }).then(async res => {
            await api.getMyCart().then(r => {
                if (r.data.myCartIds.includes(this.props.match.params.id)) {
                    this.setState({
                        inCart: true,
                    })
                }
                this.setState({
                    isLoading: false
                })
            })}).catch(e => {
                this.setState({
                    isLoading: false,
                    error: 'Erro, tente novamente.'
                })
        })    
    }

    addToCart = () => {
        this.setState({
            inCart: true
        })

        api.addToCart({_id: this.props.match.params.id}).then(async res => {
            console.log(res);
        }).catch(e => {
            this.setState({
                error: 'Erro ao adicionar a foto no carrinho, tente novamente.'
            })
        })
    }

    removeFromCart = () => {
        this.setState({
            inCart: false
        })

        api.removeFromCart({_id: this.props.match.params.id}).then(async res => {
            console.log(res);
        }).catch(e => {
            this.setState({
                error: 'Erro ao adicionar a foto no carrinho, tente novamente.'
            })
        })
    }

    render() {
        const photo = this.state.photos[0]

        return (
            <div>
                <NavBar/>
                <br/>
                {photo && !this.state.isLoading ? (
                    <div>
                        <Stepper activeStep={0} orientation="vertical" style={{paddingTop: "0px"}}>
                            <StyledStep key={"upload image"}>
                                <StepContent style={{padding: "0px", marginLeft: "0px"}} >
                                    {(
                                        <Grid style={{
                                            width: "auto",
                                            height: "auto",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column"
                                        }} container key={photo}>
                                            <p style={{color: "#c62828"}}>{this.state.error}</p>
                                            <Image src={photo.watermarkImage.url} />
                                            <br/>
                                        </Grid>
                                    )}
                                </StepContent>
                            </StyledStep>
                        </Stepper>
                        <Stepper activeStep={0} orientation="vertical" style={{paddingTop: "0px"}}>               
                            <CartPhotoButton inCart={this.state.inCart} removeFromCart={this.removeFromCart} addToCart={this.addToCart} />
                        </Stepper>
                    </div>
                ) : <CircularProgress />}


            </div>
        )
    }
}

export default BuyPhoto
