import React, { Component } from "react";
import ImageUploading from "react-images-uploading";
import { Link } from "react-router-dom";
import api from "../api";
import Chip from "@material-ui/core/Chip";
import styled from "styled-components";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { NavBar } from "../components";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import AddCircle from "@material-ui/icons/AddCircle";
import watermark from "watermarkjs";
import logo from "../logo.svg";
import imageCompression from "browser-image-compression";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";

const StyledChip = styled(Chip)`
  margin: 4px;
`;

const TagWrapper = styled.div`
  display: flex;
  justify-content: baseline;
  flex-wrap: wrap;
  list-style: none;
  padding: 4px;
  margin: 0;
`;

const InputText = styled.input.attrs({
  className: "form-control",
})`
  margin-bottom: 24px;
`;

const TextArea = styled.textarea.attrs({
  className: "form-control",
})`
  margin-bottom: 24px;
`;

const Wrapper = styled.div.attrs({
  className: "form-group",
})`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  max-width: 100vw;
`;

/*const Button = styled.button.attrs({
    className: `btn btn-primary`,
})`
margin-left: 8px;
`*/

/*const ButtonDark = styled.button.attrs({
    className: `btn btn-dark`,
})`
margin-right: 8px;
`*/
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="R$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const PRECO = 150 * 100;

class PhotoInsert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photo: "",
      isLoading: true,
      hasBankAccount: false,
      error: "",
      applyAllPhotos: false,
      open: false,
      openSubstituteDialog: false,
      dontAsk: false,
      docAllPhotos: {
        file: null,
      },
      substituteDoc: {
        file: null,
      },
      categories: [],
    };
  }

  componentDidMount = async () => {
    await api
      .hasBankAccount()
      .then((res) => {
        this.setState({
          hasBankAccount: res.data.hasBankAccount,
          isLoading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          hasBankAccount: false,
          isLoading: false,
        });
      });

    await api.getCategories().then((response) => {
      this.setState({
        categories: response.data.categories,
      });
    });
  };

  onChangePhoto = (photo) => {
    const photoEl = {
      ...photo[0],
      title: "",
      description: "",
      tag: "",
      category: [],
      doc: "",
      data: [],
      price: PRECO,
    };

    if (
      photo &&
      !(photo[0] === undefined) &&
      !this.state.photo.includes(photoEl)
    ) {
      this.setState({
        photo: photo.map((p) => {
          const isNew =
            this.state.photo && this.state.photo.find((x) => x.file === p.file);
          return {
            ...p,
            title: isNew
              ? this.state.photo.find((x) => x.file === p.file).title
              : "",
            description: isNew
              ? this.state.photo.find((x) => x.file === p.file).description
              : "",
            tag: isNew
              ? this.state.photo.find((x) => x.file === p.file).tag
              : "",
            doc: isNew
              ? this.state.photo.find((x) => x.file === p.file).doc
              : "",
            category: isNew
              ? this.state.photo.find((x) => x.file === p.file).category
              : [],
            data: isNew
              ? this.state.photo.find((x) => x.file === p.file).data
              : [],
            price: isNew
              ? this.state.photo.find((x) => x.file === p.file).price
              : PRECO,
          };
        }),
      });
    } else {
      this.setState({
        photo:
          photo[0] === undefined
            ? ""
            : photo[0] &&
              photo.map((p) => ({
                ...p,
                title: "",
                description: "",
                tag: "",
                doc: "",
                category: [],
                data: [],
                price: PRECO,
              })),
        error: "",
      });
    }
  };

  onChangeDoc = (doc) => {
    this.setState({
      doc: doc[0] && doc,
    });
  };

  hasEmptyArray = (photos, field) => photos.some((p) => p[field].length === 0);

  hasEmptyString = (photos, field) => photos.some((p) => p[field] === "");

  handleIncludePhoto = async () => {
    if (this.state.applyAllPhotos) {
      this.state.photo &&
        this.state.photo.map((image) => {
          if (image.doc === "") {
            image.doc = this.state.docAllPhotos;
          } else if (image.doc === "delete-photo") {
            image.doc = "";
          }
          return image;
        });
    }

    if (this.state.photo && this.hasEmptyString(this.state.photo, "title")) {
      window.alert("Preencha o título!");
      return;
    }

    if (
      this.state.photo &&
      this.hasEmptyString(this.state.photo, "description")
    ) {
      window.alert("Preencha a descrição!");
      return;
    }

    if (this.state.photo && this.hasEmptyArray(this.state.photo, "data")) {
      window.alert("Preencha pelo menos uma tag!");
      return;
    }

    if (this.state.photo && this.hasEmptyArray(this.state.photo, "category")) {
      window.alert("Preencha pelo menos uma categoria em todas as fotos!");
      return;
    }

    const { photo } = this.state;
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    // var count = 0
    this.setState({ isLoading: true });

    if (photo && photo.length > 0) {
      photo.map(async (p) => {
        let data = new FormData();

        const compressedImage = await imageCompression(p.file, options);
        const wmPhoto = await watermark([compressedImage, logo]).blob(function (
          image,
          logo
        ) {
          const context = image.getContext("2d");
          const logoResizedHeight = (image.width * logo.height) / logo.width;
          const logoResizedWidth = image.width;
          const posX = 0;
          const posY = (image.height - logoResizedHeight) / 2;

          context.save();

          context.globalAlpha = 0.8;
          context.drawImage(
            logo,
            posX,
            posY,
            logoResizedWidth,
            logoResizedHeight
          );

          context.restore();
          return image;
        });
        const compressedwmPhoto = await imageCompression(wmPhoto, options);

        data.append("photos", compressedImage);
        data.append("photos", compressedwmPhoto);
        data.append("photos", p.file);
        data.append("tags", JSON.stringify(p.data));
        data.append("category", JSON.stringify(p.category));
        data.append("title", p.title ? p.title : "");
        data.append("description", p.description ? p.description : "");
        data.append("price", p.price ? p.price : PRECO);
        data.append("reason", "");

        if (p.doc[0]) data.append("photos", p.doc[0]);

        await api
          .insertPhoto(data)
          .then((res) => {
            // count = count + 1
            this.setState({
              photo: "",
              doc: "",
              data: [],
              tag: "",
              category: [],
              title: "",
              isLoading: false,
              description: "",
              step: 0,
              steps: 3,
              error: "",
              price: PRECO,
            });
            // if (count === photo.length){
            //     this.setState({
            //         isLoading: false
            //     })
            //     window.alert(`Fotos adicionadas com sucesso!`)
            //     this.props.history.push('/')
            // }
            this.props.history.push("/");
          })
          .catch((e) => {
            // count = count + 1
            this.setState({
              isLoading: false,
              error: "Erro ao adicionar foto, tente novamente.",
            });
          });
      });
    } else {
      this.setState({
        isLoading: false,
        error: "Foto não adicionada!",
      });
    }
  };

  handleAdd = (key, image) => {
    if (image.tag && key === "Enter") {
      let array = image.data;
      array.push({ label: image.tag });

      this.setState({
        photo: this.state.photo.map((item) => {
          if (item === image) {
            item.data = array;
            item.tag = "";
            return item;
          }
          return item;
        }),
      });
    }
  };

  handleCloseDialog = () => {
    this.setState({
      open: false,
      openSubstituteDialog: false,
    });
  };

  handleDontAsk = () => {
    this.setState({
      dontAsk: true,
      open: false,
    });
  };

  render() {
    const handleDelete = (chipToDelete, image) => () => {
      const array = image.data;

      const index = array.indexOf(chipToDelete);
      if (index > -1) {
        array.splice(index, 1);
      }

      this.setState({
        photo: this.state.photo.map((item) => {
          if (item === image) {
            item.data = array;
            return item;
          }
          return item;
        }),
      });
    };

    return (
      <div>
        <NavBar />
        <br />
        {this.state.isLoading ? (
          <CircularProgress style={{ marginLeft: "24px" }} />
        ) : this.state.hasBankAccount ? (
          <Wrapper>
            <br />
            {this.state.error}
            <StepLabel>Faça upload das suas fotos!</StepLabel>
            <p style={{ color: "#c62828" }}>{this.state.error}</p>
            <ImageUploading
              multiple
              onChange={this.onChangePhoto}
              acceptType={["jpg", "jpeg", "png", "PNG", "JPEG", "JPG"]}
              onError={this.onError}
            >
              {({
                imageList,
                onImageUpload,
                onImageUpdate,
                onImageRemoveAll,
              }) => (
                // write your building UI
                <Wrapper>
                  {!Array.isArray(this.state.photo) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onImageUpload}
                    >
                      Upload de Imagem
                    </Button>
                  )}

                  {Array.isArray(this.state.photo) &&
                    this.state.photo.map((image) => (
                      <Wrapper key={image.key}>
                        <Image src={image.dataURL} />
                        <br />
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <InputText
                            type="text"
                            placeholder="Título da Foto *"
                            value={image.title}
                            onChange={(e) =>
                              this.setState({
                                photo: this.state.photo.map((item) => {
                                  if (item === image) {
                                    item.title = e.target.value;
                                    return item;
                                  }
                                  return item;
                                }),
                              })
                            }
                            style={{
                              marginRight: "8px",
                              marginTop: "16px",
                              maxWidth: "512px",
                            }}
                          />
                          <TextArea
                            type="text"
                            placeholder="Descrição da Foto *"
                            value={image.description}
                            onChange={(e) =>
                              this.setState({
                                photo: this.state.photo.map((item) => {
                                  if (item === image) {
                                    item.description = e.target.value;
                                    return item;
                                  }
                                  return item;
                                }),
                              })
                            }
                            style={{
                              marginRight: "8px",
                              maxWidth: "512px",
                              height: "128px",
                            }}
                          />
                        </div>
                        <Wrapper>
                          <a href="https://uinphoto-api-staging.s3.sa-east-1.amazonaws.com/authorizations_model/Contrato+Parceria+Modelo+Menor+de+Idade.docx">
                            Baixar autorização de uso de imagem do(a) modelo
                            menor de idade
                          </a>
                          <a href="https://uinphoto-api-staging.s3.sa-east-1.amazonaws.com/authorizations_model/Contrato+Parceria+Modelo+Maior+de+Idade.docx">
                            Baixar autorização de uso de imagem do(a) modelo
                            maior de idade
                          </a>
                          <br />
                          {!this.state.photo.doc &&
                            !this.state.applyAllPhotos && (
                              <Button
                                component="label"
                                variant="contained"
                                color="inherit"
                              >
                                Upload de Autorização
                                <input
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(e) =>
                                    this.setState({
                                      open: true,
                                      docAllPhotos: e.target.files[0]
                                        ? Array.from(e.target.files)
                                        : "",
                                      photo: this.state.photo.map((item) => {
                                        if (item === image) {
                                          item.doc = e.target.files[0]
                                            ? Array.from(e.target.files)
                                            : "";
                                          return item;
                                        }
                                        return item;
                                      }),
                                    })
                                  }
                                />
                              </Button>
                            )}
                          {!this.state.photo.doc &&
                            !this.state.applyAllPhotos &&
                            !this.state.dontAsk && (
                              <Dialog
                                open={this.state.open}
                                onClose={this.handleCloseDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogTitle>
                                  <span style={{ color: "black" }}>
                                    Observação
                                  </span>
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText>
                                    <span style={{ color: "black" }}>
                                      Você deseja usar essa autorização para
                                      todas as fotos?
                                    </span>
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={this.handleDontAsk}
                                  >
                                    Não perguntar mais
                                  </Button>
                                  <Button
                                    size="small"
                                    color="secondary"
                                    variant="contained"
                                    onClick={this.handleCloseDialog}
                                  >
                                    Não
                                  </Button>
                                  <Button
                                    size="small"
                                    style={{
                                      backgroundColor: "#1cce1f",
                                      color: "#ffffff",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        open: false,
                                        applyAllPhotos: true,
                                        docAllPhotos: this.state.docAllPhotos,
                                        photo: this.state.photo.map((item) => {
                                          item.doc = this.state.docAllPhotos;
                                          return item;
                                        }),
                                      })
                                    }
                                  >
                                    Sim
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            )}
                          {(image.doc || this.state.applyAllPhotos) && (
                            <Wrapper>
                              {image.doc && image.doc[0] ? (
                                <p>
                                  <br />
                                  {image.doc[0].name}
                                </p>
                              ) : this.state.docAllPhotos[0] &&
                                image.doc !== "delete-photo" ? (
                                <p>
                                  <br />
                                  {this.state.docAllPhotos[0].name}
                                </p>
                              ) : (
                                ""
                              )}
                              {image.doc === "delete-photo" &&
                                this.state.applyAllPhotos && (
                                  <Button
                                    component="label"
                                    variant="contained"
                                    color="inherit"
                                  >
                                    Upload de Autorização
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={(e) =>
                                        this.setState({
                                          openSubstituteDialog: true,
                                          substituteDoc: e.target.files[0]
                                            ? Array.from(e.target.files)
                                            : "",
                                          photo: this.state.photo.map(
                                            (item) => {
                                              if (item === image) {
                                                item.doc = e.target.files[0]
                                                  ? Array.from(e.target.files)
                                                  : "";
                                                return item;
                                              }
                                              return item;
                                            }
                                          ),
                                        })
                                      }
                                    />
                                  </Button>
                                )}
                              {image.doc !== "delete-photo" && (
                                <div style={{ paddingTop: "8px" }}>
                                  <Button
                                    component="label"
                                    variant="contained"
                                    color="secondary"
                                    style={{ marginRight: "8px" }}
                                    onClick={(e) =>
                                      this.setState({
                                        photo: this.state.photo.map((item) => {
                                          if (item === image) {
                                            item.doc = "delete-photo";
                                            return item;
                                          }
                                          return item;
                                        }),
                                      })
                                    }
                                  >
                                    Remover
                                  </Button>
                                  <Button
                                    component="label"
                                    variant="contained"
                                    color="inherit"
                                    style={{ marginRight: "8px" }}
                                  >
                                    Substituir
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={(e) =>
                                        this.setState({
                                          openSubstituteDialog: true,
                                          substituteDoc: e.target.files[0]
                                            ? Array.from(e.target.files)
                                            : "",
                                          photo: this.state.photo.map(
                                            (item) => {
                                              if (item === image) {
                                                item.doc = e.target.files[0]
                                                  ? Array.from(e.target.files)
                                                  : "";
                                                return item;
                                              }
                                              return item;
                                            }
                                          ),
                                        })
                                      }
                                    />
                                  </Button>
                                  <Dialog
                                    open={this.state.openSubstituteDialog}
                                    onClose={this.handleCloseDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                  >
                                    <DialogTitle>
                                      <span style={{ color: "black" }}>
                                        Observação
                                      </span>
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText>
                                        <span style={{ color: "black" }}>
                                          Você deseja substituir a autorização
                                          para todas as fotos?
                                        </span>
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        size="small"
                                        color="secondary"
                                        variant="contained"
                                        onClick={this.handleCloseDialog}
                                      >
                                        Não
                                      </Button>
                                      <Button
                                        size="small"
                                        style={{
                                          backgroundColor: "#1cce1f",
                                          color: "#ffffff",
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            openSubstituteDialog: false,
                                            applyAllPhotos: true,
                                            docAllPhotos:
                                              this.state.substituteDoc,
                                            photo: this.state.photo.map(
                                              (item) => {
                                                item.doc =
                                                  this.state.substituteDoc;
                                                return item;
                                              }
                                            ),
                                          })
                                        }
                                      >
                                        Sim
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </div>
                              )}
                            </Wrapper>
                          )}
                        </Wrapper>

                        <TagWrapper>
                          {image.data.map((data) => (
                            <li key={data.key}>
                              <StyledChip
                                label={data.label}
                                onDelete={handleDelete(data, image)}
                              />
                            </li>
                          ))}
                        </TagWrapper>

                        <div style={{ display: "flex", height: "38px" }}>
                          <InputText
                            type="text"
                            placeholder="Adicione tags para a foto *"
                            value={image.tag}
                            onChange={(e) =>
                              this.setState({
                                photo: this.state.photo.map((item) => {
                                  if (item === image) {
                                    item.tag = e.target.value;
                                    return item;
                                  }
                                  return item;
                                }),
                              })
                            }
                            onKeyDown={(e) => this.handleAdd(e.key, image)}
                            style={{ width: "256px", marginRight: "8px" }}
                          />
                          <IconButton
                            onClick={() => this.handleAdd("Enter", image)}
                            variant="contained"
                            color="primary"
                          >
                            <AddCircle />
                          </IconButton>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            height: "38px",
                          }}
                        >
                          <FormControl
                            variant="outlined"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "16px",
                              marginLeft: "8px",
                              marginRight: "8px",
                              marginBottom: "16px",
                              minWidth: "256px",
                            }}
                          >
                            <InputLabel required>Categorias</InputLabel>
                            <Select
                              multiple
                              value={image.category || []}
                              onChange={(e) =>
                                this.setState({
                                  photo: this.state.photo.map((item) => {
                                    if (item === image) {
                                      item.category = e.target.value;
                                      return item;
                                    }
                                    return item;
                                  }),
                                })
                              }
                              input={<Input />}
                              renderValue={(selected) =>
                                this.state.categories
                                  .filter((c) => image.category.includes(c._id))
                                  .map((c) => c.title)
                                  .join(", ")
                              }
                            >
                              {this.state.categories.map((category) => (
                                <MenuItem value={category._id}>
                                  <Checkbox
                                    checked={
                                      image.category.indexOf(category._id) > -1
                                    }
                                  />
                                  <ListItemText primary={category.title} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Wrapper>
                    ))}
                  {this.state.photo && this.state.photo.length > 0 ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "32px",
                        }}
                      >
                        <Button
                          component="label"
                          variant="contained"
                          color="inherit"
                          onClick={onImageUpload}
                        >
                          Adicionar Outra Foto
                        </Button>
                      </div>
                      <br />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          component="label"
                          variant="contained"
                          color="secondary"
                          style={{ marginRight: "8px" }}
                          onClick={onImageRemoveAll}
                        >
                          Cancelar
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleIncludePhoto}
                        >
                          Finalizar
                        </Button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Wrapper>
              )}
            </ImageUploading>
            <br />
          </Wrapper>
        ) : (
          <Wrapper>
            <div>
              Você precisa preencher seus dados bancários para realizar upload
              de fotos
            </div>
            <Link to="/edit-profile">
              Clique aqui e preencha seus dados bancários
            </Link>
          </Wrapper>
        )}
      </div>
    );
  }
}

export default PhotoInsert;
