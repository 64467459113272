import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MoreIcon from '@material-ui/icons/MoreVert';
import { isAuthenticated, getToken, logout } from "../api/auth";
import api from "../api";
import {Link} from "react-router-dom";
import Logo from '../assets/uin-stock-logo-plataforma-positivo.svg';
import Badge from "@material-ui/core/Badge";
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import Divider from "@material-ui/core/Divider"

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    actualButton: {
        margin: theme.spacing(1),
        backgroundColor: "#ff7c00",
        color: "white",
        '&:hover': {
            backgroundColor: '#ff7c00',
            color: 'white',
        },
    },
    buttons: {
        margin: theme.spacing(1),
        backgroundColor: "#ff5100",
        color: "white",
        '&:hover': {
            backgroundColor: '#ff5100',
            color: 'white',
        },
    },
    dividerColor: {
        backgroundColor: '#ff5100',
      },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconButtonBar: {
        marginLeft: theme.spacing(4),
        height: 20,
        width: 20
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default function PrimarySearchAppBar() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [itemCount, setItemCount] = React.useState(0);
    const [typeUser, settypeUser] = React.useState(false);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [pathName, setPathName] = React.useState('')
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const history = useHistory()

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    // eslint-disable-next-line
    React.useEffect(() => {

        setPathName(window.location.pathname)
        const token = getToken();
        api.typeUser(token).then(response => {
            settypeUser(response.data.typeUser);
        }).catch(err => {
            console.log(err);
        });

        api.getMyCart().then(response => {
            setItemCount(response.data.cart.photos.length);
        }).catch(e => {
            console.log(e);
        })

        setLoading(false);
    });

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
                <Menu
                    anchorEl={mobileMoreAnchorEl}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    id={mobileMenuId}
                    keepMounted
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    open={isMobileMenuOpen}
                    onClose={handleMobileMenuClose}
                >   
                    { typeUser === "Admin" ? <MenuItem onClick={() => { history.push('/users')}}>Usuário</MenuItem> : "" }
                    <MenuItem onClick={() => { history.push('/store') }}>Galeria</MenuItem>
                    <MenuItem onClick={() => { history.push('/') }}>Minha Galeria</MenuItem>
                    { typeUser === "Admin" || typeUser === "User" ? <MenuItem onClick={() => { history.push('/my-sales') }}>Minhas Vendas</MenuItem> : "" }
                    { typeUser === "Admin" || typeUser === "User" ? <MenuItem onClick={() => { history.push('/photo') }}>Adicionar Foto</MenuItem> : "" }
                    <MenuItem onClick={logout}>Sair</MenuItem>
                </Menu>
        );

    const adminButtons = (
        <Button onClick={() => { history.push('/users') }} className={pathName === '/users' ? classes.actualButton : classes.buttons} size="small" variant="contained">
            Usuários e Categorias
        </Button>
    )

    const StoreButtons = (
        <Button onClick={() => { history.push('/store') }} className={pathName === '/store' ? classes.actualButton : classes.buttons} size="small" variant="contained">
            Galeria
        </Button>
    )

    const galleryButtons = (
        <Button onClick={() => { history.push('/') }} size="small" className={pathName === '/' ? classes.actualButton : classes.buttons} variant="contained">
            Minha Galeria
        </Button>
    )

    const mySalesButtons = (
        <Button onClick={() => { history.push('/my-sales') }} size="small" className={pathName === '/my-sales' ? classes.actualButton : classes.buttons} variant="contained">
            Minhas Vendas
        </Button>
    )

    const addPhotoButtons = (
        <Button onClick={() => { history.push('/photo') }} size="small" className={pathName === '/photo' ? classes.actualButton : classes.buttons} variant="contained">
            Adicionar Foto
        </Button>
    )

    const shoppingCart = (
        <Button 
        onClick={() => { history.push('/cart') }} 
        className={pathName === '/cart' ? classes.actualButton : classes.buttons}
        size="small" 
        variant="contained"
        startIcon={
            <Badge color="primary" badgeContent={itemCount}>
                <ShoppingCartIcon />
            </Badge>
        }>
                Carrinho
        </Button>
    )

    const logoutButtons = (
        <Button onClick={logout} size="small" className={classes.buttons} variant="contained">
            Sair
        </Button>
    )

    const component = loading ? '' : (
        <div className={classes.grow}>
            <AppBar position="static" elevation={0} style={{backgroundColor: "white"}}>
                <Toolbar>
                    <Typography className={classes.title} variant="h6" noWrap>
                        {
                            isAuthenticated() ? 
                            (
                                <Link to="/store" style={{color: "#FFF"}}>
                                    <img style={{height: '28px'}} src={ Logo } alt="Logo" />
                                </Link>    
                            ) : (
                                <Link onClick={() => window.location.href = "https://uinstock.net"} style={{color: "#FFF"}}>
                                    <img style={{height: '28px'}} src={ Logo } alt="Logo" />
                                </Link>    
                            )
                        }
                    </Typography>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        {
                            isAuthenticated() ?
                                (
                                    <div>
                                        { typeUser === "Admin" ? adminButtons : "" }
                                        { StoreButtons }
                                        { galleryButtons }
                                        { typeUser === "Admin" || typeUser === "User" ? mySalesButtons : "" }
                                        { typeUser === "Admin" || typeUser === "User" ? addPhotoButtons : "" }
                                        { typeUser === "Consumer" ? shoppingCart : "" }
                                        { logoutButtons }
                                    </div>
                                )
                                :
                                ""
                        }
                    </div>
                    <div className={classes.sectionMobile}>
                    { typeUser === "Consumer" ? shoppingCart : "" }
                        {
                            isAuthenticated() ?
                                (
                                    <IconButton
                                        aria-label="show more"
                                        aria-controls={mobileMenuId}
                                        aria-haspopup="true"
                                        onClick={handleMobileMenuOpen}
                                        color="black"
                                    >
                                        <MoreIcon />
                                    </IconButton>
                                )
                                :
                                ""
                        }

                    </div>
                </Toolbar>
            </AppBar>
            <Divider variant="middle" className={classes.dividerColor} style={{marginLeft: "16px", marginRight: "16px", width: "97%"}}/>
            {renderMobileMenu}
            {renderMenu}
        </div>
    )

    return component;
}