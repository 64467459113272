import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { isAuthenticated, logout } from "../api/auth";
import InputBase from "@material-ui/core/InputBase";


const Collapse = styled.div.attrs({
    className: 'collpase navbar-collapse',
})`
`

const List = styled.div.attrs({
    className: 'navbar-nav ml-auto',
})`
display: flex;

`

const Item = styled.div.attrs({
    className: 'collpase navbar-collapse',
})`
`

const SearchDiv = styled.div`
position: relative;
border-radius: 4px;
background-color: #FFFFFF;
margin-right: 8px;
margin-top: 4px;
margin-bottom: 4px;
width: 217px;
height: 40px;
display: flex;
align-items: center;
&:hover: {
    background-color: #FFFFFF;
};
`


class Links extends Component {
    render() {
        return (
            <React.Fragment>
                <Link to="/" className="navbar-brand">
                    uinstock
                </Link>

                <Collapse>
                    {
                        isAuthenticated() ?
                            (
                                <List>
                                    <SearchDiv>
                                        <InputBase
                                            placeholder="Search…"
                                            style={{
                                                marginLeft: "8px"
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </SearchDiv>
                                    <Item>
                                        <Link to="/" className="nav-link">
                                            Galeria
                                        </Link>
                                    </Item>
                                    <Item>
                                        <Link to="/photo" className="nav-link">
                                            Carregar Foto
                                        </Link>
                                    </Item>
                                    <Item>
                                        <Link to="/" onClick={logout} className="nav-link">
                                            Logout
                                        </Link>
                                    </Item>
                                </List>
                            ) :
                            (
                                <List>
                                    <Item>
                                        <Link to="/" className="nav-link">
                                            Login
                                        </Link>
                                    </Item>
                                    <Item>
                                        <Link to="/signup" className="nav-link">
                                            Cadastrar
                                        </Link>
                                    </Item>
                                </List>
                            )
                    }

                </Collapse>
            </React.Fragment>
        )
    }
}

export default Links
