import "react-table/react-table.css";
import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import api from "../api";

import { Wrapper, Image } from ".";
import CreditDialog from "./CreditDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginLeft: "36px",
    padding: theme.spacing(2),
    marginRight: "auto",
    width: "100%",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    padding: theme.spacing(1),
  },
  icon: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    padding: theme.spacing(1),
  },
  card: {
    margin: "auto",
    marginTop: theme.spacing(2),
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    padding: theme.spacing(1),
  },
}));

export default function CartInformation({
  photos,
  filterLogic,
  history,
  toggleRaised,
  raised,
  addToCart,
  removeFromCart,
  myCartIds,
}) {
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [openCreditDialog, setOpenCreditDialog] = React.useState(false);
  const [packs, setPacks] = React.useState([]);
  const [myCredits, setMyCredits] = React.useState(undefined);

  const classes = useStyles();

  useEffect(() => {
    const onLoadPacks = async () => {
      await api.loadPacks().then((response) => {
        setPacks(response.data.packs);
      });
    };

    const onLoadMyCredits = async () => {
      await api.loadMyCredits().then((response) => {
        setMyCredits(response.data.credits);
      });
    };

    onLoadPacks();
    onLoadMyCredits();
  }, []);

  const openCheckout = (value) => {
    const amount = totalToPay();
    const { recipients, creditRecipients } = getRecipients();

    const items_checkout = photos.photos.map((p) => ({
      id: p._id,
      title: "photo",
      unit_price: p.price,
      quantity: 1,
      tangible: "false",
    }));

    setLoading(true);

    if (amount > 0) {
      let checkout = new window.PagarMeCheckout.Checkout({
        encryption_key: "ek_live_bo7nJA2TLAdWmhZqX3hVUBqeRQtibo",
        success: async function (data) {
          setStatus("Adicionando as fotos em sua galeria...");

          // aqui envia pro back e realiza a captura
          await api.confirmPayment({
            token: data.token,
            payment_method: data.payment_method,
            credit_recipients: creditRecipients,
            recipients: recipients,
            amount: amount
          });

          setLoading(false);
          history.push("/");
        },
        error: function (err) {
          setLoading(false);
          console.log(JSON.stringify(err));
        },
        close: function () {
          setLoading(false);
          //   alert("The modal has been closed.");
        },
      });

      checkout.open({
        amount: amount,
        buttonText: "Pagar",
        customerData: "true",
        createToken: "true",
        capture: "true",
        paymentMethods: "credit_card",
        items: items_checkout,
      });
    } else {
      setLoading(false);
      // aqui envia pro back e realiza a captura

      const confirmPayment = async () => {
        await api.confirmPayment({
          token: undefined,
          payment_method: "credit",
          credit_recipients: creditRecipients,
          recipients: recipients,
          amount: amount
        });

        history.push("/");
      };

      confirmPayment();
    }
  };

  const handleMoreCredits = () => {
    setOpenCreditDialog(true);
  };

  const handleCreditPayment = (id, amount) => {
    setOpenCreditDialog(false);

    let checkout = new window.PagarMeCheckout.Checkout({
      encryption_key: "ek_live_bo7nJA2TLAdWmhZqX3hVUBqeRQtibo",
      success: async function (data) {
        setStatus("Adicionando cŕeditos...");

        // aqui envia pro back e realiza a captura
        await api.confirmCreditPayment({
          token: data.token,
          payment_method: data.payment_method,
          recipients: [],
          credit_id: id,
          amount: amount
        });

        setLoading(false);
        window.location.reload();
      },
      error: function (err) {
        setLoading(false);
        console.log(JSON.stringify(err));
      },
      close: function () {
        setLoading(false);
        //   alert("The modal has been closed.");
      },
    });

    checkout.open({
      amount: amount,
      buttonText: "Pagar",
      customerData: "true",
      createToken: "true",
      capture: "true",
      paymentMethods: "credit_card",
      items: [
        {
          id: id,
          title: "photo",
          unit_price: amount,
          quantity: 1,
          tangible: "false",
        },
      ],
    });
  };

  const creditsAfterBuy = () => {
    let posCredit = 0;

    if (myCredits && photos && photos.photos) {
      posCredit = myCredits - photos.photos.length;
    }

    if (posCredit > 0) return posCredit;

    return 0;
  };

  const positionAfterCredit = () => {
    return myCredits - creditsAfterBuy(myCredits);
  };

  const getRecipients = () => {
    const afterCredit = positionAfterCredit();

    const recipients = photos.photos.slice(afterCredit).map((p) => ({
      owner: p.owner,
      unit_price: p.price,
    }));

    const creditRecipients = photos.photos.slice(0, afterCredit).map((p) => ({
      owner: p.owner,
      unit_price: p.price,
    }));

    return {
      recipients,
      creditRecipients,
    };
  };

  const totalToPay = () => {
    let pay = 0;

    const afterCredit = positionAfterCredit();
    if (photos && photos.photos)
      pay = photos.photos
        .slice(afterCredit)
        .reduce((n, { price }) => n + price, 0);

    return pay;
  };

  const totalToPayStr = () => {
    return (totalToPay() / 100).toFixed(2);
  };

  const component = (
    <Wrapper>
      <paper className={classes.paper}>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p>{status}</p>
            <br />
            <CircularProgress />
          </div>
        ) : (
          ""
        )}
        <CreditDialog
          open={openCreditDialog}
          setOpenDialog={setOpenCreditDialog}
          handlePayment={handleCreditPayment}
          packs={packs}
        />
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={12} sm={8} md={8}>
            <Grid container direction="column" style={{ width: "100%" }}>
              {photos && photos.photos && photos.photos.length !== 0
                ? photos.photos
                    .filter((photo) => filterLogic(photo))
                    .map((photo) => (
                      <Grid
                        style={{ display: "flex", justifyContent: "center" }}
                        container
                      >
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ButtonBase className={classes.image}>
                            <Image
                              className={classes.img}
                              onClick={() =>
                                history.push("/buy-photo/" + photo._id)
                              }
                              src={photo.watermarkImage.url}
                            />
                          </ButtonBase>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          sm={4}
                          md={4}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Grid item>
                            <Typography gutterBottom variant="subtitle1">
                              {photo.title ? photo.title : "Sem Título"}
                            </Typography>
                            {/* <Typography gutterBottom variant="subtitle1">
                                                    {photo.description ? photo.description : 'Sem Descrição'}
                                                </Typography> */}
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle1">
                              Preço: R${" "}
                              {photo.price
                                ? (photo.price / 100).toFixed(2)
                                : "Sem preço"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          <button
                            justify="flex-start"
                            alignItems="center"
                            onClick={() => removeFromCart({ _id: photo._id })}
                            className="btn btn-danger btn-sm mb-1"
                          >
                            <DeleteIcon width={"20px"} />
                          </button>
                        </Grid>
                        <Divider
                          variant="middle"
                          style={{ marginBottom: "16px", width: "80%" }}
                        />
                      </Grid>
                    ))
                : ""}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid
              container
              spacing={4}
              flex-direction="column"
              justifyContent="center"
              style={{ width: "100%" }}
            >
              <Grid item className={classes.card}>
                <Typography gutterBottom variant="h9">
                  Créditos Disponíveis
                </Typography>
                <Typography gutterBottom variant="h5">
                  {myCredits && myCredits}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleMoreCredits}
                >
                  Mais Créditos
                </Button>
                <Divider
                  variant="middle"
                  style={{
                    marginBottom: "16px",
                    marginTop: "16px",
                    width: "80%",
                  }}
                />
                <Typography gutterBottom variant="h9">
                  Total de imagens
                </Typography>
                <Typography gutterBottom variant="h5">
                  {photos.photos ? photos.photos.length : ""}
                </Typography>
                <Typography gutterBottom variant="h9">
                  Total a pagar
                </Typography>
                <Typography gutterBottom variant="h5">
                  R$ {totalToPayStr()}
                </Typography>
                <Typography gutterBottom variant="h9">
                  Créditos a utilizar
                </Typography>
                <Typography gutterBottom variant="h5">
                  {photos.photos ? Math.min(photos.photos.length, myCredits ? myCredits : 0): ""}
                </Typography>
                <Divider
                  variant="middle"
                  style={{ marginBottom: "16px", width: "80%" }}
                />
                <Button
                  disabled={
                    !photos || !photos.photos || photos.photos.length === 0
                  }
                  variant="contained"
                  color="primary"
                  onClick={() => openCheckout(photos.photos)}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </paper>
    </Wrapper>
  );

  return component;
}
