import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import api from "../api";
import { NavBar } from "../components";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const Wrapper = styled.div.attrs({
  className: "form-group",
})`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
`;

// const InputText = styled.input.attrs({
//     className: 'form-control',
// })`
//     margin-bottom: 24px;
// `

const Button = styled.button.attrs({
  className: `btn btn-primary`,
})``;

class EditProfile extends Component {
  state = {
    bank_code: "",
    agencia: "",
    agenciaDV: "",
    conta: "",
    type: "conta_corrente",
    conta_dv: "",
    document_number: "",
    error: "",
  };

  finalizar = async () => {
    const {
      bank_code,
      agencia,
      agenciaDV,
      conta,
      type,
      conta_dv,
      document_number,
    } = this.state;
    const payload = {
      bank_code: bank_code,
      agencia: agencia,
      agencia_dv: agenciaDV,
      conta: conta,
      type: type,
      conta_dv: conta_dv,
      document_number: document_number,
    };

    if (
      !bank_code ||
      !agencia ||
      !conta ||
      !type ||
      !conta_dv ||
      !document_number
    ) {
      this.setState({ error: "Preencha todos os dados para se cadastrar" });
    } else {
      await api
        .editProfile(payload)
        .then((res) => {
          window.alert(`Dados atualizados com sucesso!`);
          this.props.history.push("/photo");
        })
        .catch((err) => {
          this.setState({ error: err.response.data.error });
        });
    }
  };

  render() {
    const { agencia, agenciaDV, conta, conta_dv, document_number } = this.state;
    const apiBanks = [
      "237",
      "001",
      "341",
      "260",
      "197",
      "104",
      "033",
      "077",
      "330",
      "208",
      "422",
      "323",
      "290",
      "184",
      "479",
      "756",
      "000",
    ];
    const totalBanks = [
      { bank_code: "001", bank_name: "Banco Do Brasil S.A (BB)" },
      { bank_code: "237", bank_name: "Bradesco S.A." },
      { bank_code: "335", bank_name: "Banco Digio S.A." },
      { bank_code: "260", bank_name: "Nu Pagamentos S.A (Nubank)" },
      { bank_code: "290", bank_name: "PagSeguro Internet S.A." },
      { bank_code: "323", bank_name: "Mercado Pago – Conta Do Mercado Livre" },
      { bank_code: "237", bank_name: "Next Bank (Mesmo Código Do Bradesco)" },
      { bank_code: "637", bank_name: "Banco Sofisa S.A (Sofisa Direto)" },
      { bank_code: "077", bank_name: "Banco Inter S.A." },
      { bank_code: "341", bank_name: "Itaú Unibanco S.A." },
      { bank_code: "104", bank_name: "Caixa Econômica Federal (CEF)" },
      { bank_code: "033", bank_name: "Banco Santander Brasil S.A." },
      { bank_code: "212", bank_name: "Banco Original S.A." },
      {
        bank_code: "756",
        bank_name: "Bancoob – Banco Cooperativo Do Brasil S.A.",
      },
      { bank_code: "655", bank_name: "Banco Votorantim S.A." },
      {
        bank_code: "655",
        bank_name: "Neon Pagamentos S.A (Memso Código Do Banco Votorantim)",
      },
      {
        bank_code: "041",
        bank_name: "Banrisul – Banco Do Estado Do Rio Grande Do Sul S.A.",
      },
      { bank_code: "389", bank_name: "Banco Mercantil Do Brasil S.A." },
      { bank_code: "422", bank_name: "Banco Safra S.A." },
      { bank_code: "070", bank_name: "BRB – Banco De Brasília S.A." },
      { bank_code: "136", bank_name: "Unicred Cooperativa LTDA" },
      { bank_code: "741", bank_name: "Banco Ribeirão Preto S.A." },
      { bank_code: "739", bank_name: "Banco Cetelem S.A." },
      { bank_code: "743", bank_name: "Banco Semear S.A." },
      { bank_code: "100", bank_name: "Planner Corretora De Valores S.A." },
      { bank_code: "096", bank_name: "Banco B3 S.A." },
      {
        bank_code: "747",
        bank_name: "Banco Rabobank Internacional Do Brasil S.A.",
      },
      { bank_code: "748", bank_name: "Banco Cooperativa Sicredi S.A." },
      { bank_code: "752", bank_name: "Banco BNP Paribas Brasil S.A." },
      { bank_code: "091", bank_name: "Unicred Central Rs" },
      { bank_code: "399", bank_name: "Kirton Bank S.A. – Banco Múltiplo" },
      { bank_code: "108", bank_name: "Portocred S.A." },
      { bank_code: "757", bank_name: "Banco Keb Hana Do Brasil S.A." },
      { bank_code: "102", bank_name: "XP Investimentos S.A." },
      { bank_code: "348", bank_name: "Banco XP S/A" },
      { bank_code: "340", bank_name: "Super Pagamentos S/A (Superdital)" },
      { bank_code: "084", bank_name: "Uniprime Norte Do Paraná" },
      { bank_code: "180", bank_name: "Cm Capital Markets Cctvm Ltda" },
      { bank_code: "066", bank_name: "Banco Morgan Stanley S.A." },
      { bank_code: "015", bank_name: "UBS Brasil Cctvm S.A." },
      { bank_code: "143", bank_name: "Treviso Cc S.A." },
      { bank_code: "062", bank_name: "Hipercard Banco Múltiplo S.A." },
      { bank_code: "074", bank_name: "Banco J. Safra S.A." },
      { bank_code: "099", bank_name: "Uniprime Central Ccc Ltda" },
      { bank_code: "025", bank_name: "Banco Alfa S.A." },
      { bank_code: "075", bank_name: "Bco Abn Amro S.A." },
      { bank_code: "040", bank_name: "Banco Cargill S.A." },
      { bank_code: "190", bank_name: "Servicoop" },
      { bank_code: "063", bank_name: "Banco Bradescard" },
      { bank_code: "191", bank_name: "Nova Futura Ctvm Ltda" },
      { bank_code: "064", bank_name: "Goldman Sachs Do Brasil Bm S.A." },
      { bank_code: "097", bank_name: "Ccc Noroeste Brasileiro Ltda" },
      { bank_code: "016", bank_name: "Ccm Desp Trâns Sc E Rs" },
      { bank_code: "012", bank_name: "Banco Inbursa" },
      { bank_code: "003", bank_name: "Banco Da Amazônia S.A." },
      { bank_code: "060", bank_name: "Confidence Cc S.A." },
      { bank_code: "037", bank_name: "Banco Do Estado Do Pará S.A." },
      { bank_code: "159", bank_name: "Casa do Crédito S.A." },
      { bank_code: "172", bank_name: "Albatross Ccv S.A." },
      {
        bank_code: "085",
        bank_name: "Cooperativa Central de Créditos – Ailos",
      },
      {
        bank_code: "114",
        bank_name: "Central Cooperativa De Crédito no Estado do Espírito Santo",
      },
      { bank_code: "036", bank_name: "Banco Bradesco BBI S.A." },
      { bank_code: "394", bank_name: "Banco Bradesco Financiamentos S.A." },
      { bank_code: "004", bank_name: "Banco Do Nordeste Do Brasil S.A." },
      {
        bank_code: "320",
        bank_name: "China Construction Bank – Ccb Brasil S.A.",
      },
      { bank_code: "189", bank_name: "Hs Financeira" },
      { bank_code: "105", bank_name: "Lecca Cfi S.A." },
      { bank_code: "076", bank_name: "Banco KDB Brasil S.A." },
      { bank_code: "082", bank_name: "Banco Topázio S.A." },
      {
        bank_code: "286",
        bank_name: "Cooperativa de Crédito Rural de De Ouro",
      },
      { bank_code: "093", bank_name: "PóloCred Scmepp Ltda" },
      { bank_code: "273", bank_name: "Ccr De São Miguel Do Oeste" },
      { bank_code: "157", bank_name: "Icap Do Brasil Ctvm Ltda" },
      { bank_code: "183", bank_name: "Socred S.A." },
      { bank_code: "014", bank_name: "Natixis Brasil S.A." },
      { bank_code: "130", bank_name: "Caruana Scfi" },
      { bank_code: "127", bank_name: "Codepe Cvc S.A." },
      { bank_code: "079", bank_name: "Banco Original Do Agronegócio S.A." },
      { bank_code: "081", bank_name: "Bbn Banco Brasileiro De Negocios S.A." },
      { bank_code: "118", bank_name: "Standard Chartered Bi S.A." },
      { bank_code: "133", bank_name: "Cresol Confederação" },
      { bank_code: "121", bank_name: "Banco Agibank S.A." },
      { bank_code: "083", bank_name: "Banco Da China Brasil S.A." },
      { bank_code: "138", bank_name: "Get Money Cc Ltda" },
      { bank_code: "024", bank_name: "Banco Bandepe S.A." },
      { bank_code: "095", bank_name: "Banco Confidence De Câmbio S.A." },
      { bank_code: "094", bank_name: "Banco Finaxis" },
      { bank_code: "276", bank_name: "Senff S.A." },
      { bank_code: "137", bank_name: "Multimoney Cc Ltda" },
      { bank_code: "092", bank_name: "BRK S.A." },
      { bank_code: "047", bank_name: "Banco do Estado de Sergipe S.A." },
      { bank_code: "144", bank_name: "Bexs Banco De Cambio S.A." },
      { bank_code: "126", bank_name: "BR Partners Banco de Investimento S.A." },
      { bank_code: "301", bank_name: "BPP Instituição De Pagamentos S.A." },
      { bank_code: "173", bank_name: "BRL Trust Dtvm Sa" },
      { bank_code: "119", bank_name: "Banco Western Union do Brasil S.A." },
      { bank_code: "254", bank_name: "Paraná Banco S.A." },
      { bank_code: "268", bank_name: "Barigui Companhia Hipotecária" },
      { bank_code: "107", bank_name: "Banco Bocom BBM S.A." },
      { bank_code: "412", bank_name: "Banco Capital S.A." },
      { bank_code: "124", bank_name: "Banco Woori Bank Do Brasil S.A." },
      { bank_code: "149", bank_name: "Facta S.A. Cfi" },
      { bank_code: "197", bank_name: "Stone Pagamentos S.A." },
      { bank_code: "142", bank_name: "Broker Brasil Cc Ltda" },
      { bank_code: "389", bank_name: "Banco Mercantil Do Brasil S.A." },
      { bank_code: "184", bank_name: "Banco Itaú BBA S.A." },
      { bank_code: "634", bank_name: "Banco Triangulo S.A (Banco Triângulo)" },
      { bank_code: "545", bank_name: "Senso Ccvm S.A." },
      { bank_code: "132", bank_name: "ICBC do Brasil Bm S.A." },
      { bank_code: "298", bank_name: "Vip’s Cc Ltda" },
      { bank_code: "129", bank_name: "UBS Brasil Bi S.A." },
      { bank_code: "128", bank_name: "Ms Bank S.A Banco De Câmbio" },
      { bank_code: "194", bank_name: "Parmetal Dtvm Ltda" },
      { bank_code: "310", bank_name: "VORTX Dtvm Ltda" },
      { bank_code: "163", bank_name: "Commerzbank Brasil S.A Banco Múltiplo" },
      { bank_code: "280", bank_name: "Avista S.A." },
      { bank_code: "146", bank_name: "Guitta Cc Ltda" },
      { bank_code: "279", bank_name: "Ccr De Primavera Do Leste" },
      { bank_code: "182", bank_name: "Dacasa Financeira S/A" },
      { bank_code: "278", bank_name: "Genial Investimentos Cvm S.A." },
      { bank_code: "271", bank_name: "Ib Cctvm Ltda" },
      { bank_code: "021", bank_name: "Banco Banestes S.A." },
      { bank_code: "246", bank_name: "Banco Abc Brasil S.A." },
      { bank_code: "751", bank_name: "Scotiabank Brasil" },
      { bank_code: "208", bank_name: "Banco BTG Pactual S.A." },
      { bank_code: "746", bank_name: "Banco Modal S.A." },
      { bank_code: "241", bank_name: "Banco Classico S.A." },
      { bank_code: "612", bank_name: "Banco Guanabara S.A." },
      { bank_code: "604", bank_name: "Banco Industrial Do Brasil S.A." },
      { bank_code: "505", bank_name: "Banco Credit Suisse (Brl) S.A." },
      { bank_code: "196", bank_name: "Banco Fair Cc S.A." },
      { bank_code: "300", bank_name: "Banco La Nacion Argentina" },
      { bank_code: "477", bank_name: "Citibank N.A." },
      { bank_code: "266", bank_name: "Banco Cedula S.A." },
      { bank_code: "122", bank_name: "Banco Bradesco BERJ S.A." },
      { bank_code: "376", bank_name: "Banco J.P. Morgan S.A." },
      { bank_code: "473", bank_name: "Banco Caixa Geral Brasil S.A." },
      { bank_code: "745", bank_name: "Banco Citibank S.A." },
      { bank_code: "120", bank_name: "Banco Rodobens S.A." },
      { bank_code: "265", bank_name: "Banco Fator S.A." },
      {
        bank_code: "007",
        bank_name: "BNDES (Banco Nacional Do Desenvolvimento Social)",
      },
      { bank_code: "188", bank_name: "Ativa S.A Investimentos" },
      { bank_code: "134", bank_name: "BGC Liquidez Dtvm Ltda" },
      { bank_code: "641", bank_name: "Banco Alvorada S.A." },
      { bank_code: "029", bank_name: "Banco Itaú Consignado S.A." },
      { bank_code: "243", bank_name: "Banco Máxima S.A." },
      { bank_code: "078", bank_name: "Haitong Bi Do Brasil S.A." },
      { bank_code: "111", bank_name: "Banco Oliveira Trust Dtvm S.A." },
      { bank_code: "017", bank_name: "Bny Mellon Banco S.A." },
      { bank_code: "174", bank_name: "Pernambucanas Financ S.A." },
      { bank_code: "495", bank_name: "La Provincia Buenos Aires Banco" },
      { bank_code: "125", bank_name: "Brasil Plural S.A Banco" },
      { bank_code: "488", bank_name: "Jpmorgan Chase Bank" },
      { bank_code: "065", bank_name: "Banco Andbank S.A." },
      { bank_code: "492", bank_name: "Ing Bank N.V." },
      { bank_code: "250", bank_name: "Banco Bcv" },
      { bank_code: "145", bank_name: "Levycam Ccv Ltda" },
      { bank_code: "494", bank_name: "Banco Rep Oriental Uruguay" },
      { bank_code: "253", bank_name: "Bexs Cc S.A." },
      { bank_code: "269", bank_name: "Hsbc Banco De Investimento" },
      { bank_code: "213", bank_name: "Bco Arbi S.A." },
      { bank_code: "139", bank_name: "Intesa Sanpaolo Brasil S.A." },
      { bank_code: "018", bank_name: "Banco Tricury S.A." },
      { bank_code: "630", bank_name: "Banco Intercap S.A." },
      { bank_code: "224", bank_name: "Banco Fibra S.A." },
      { bank_code: "600", bank_name: "Banco Luso Brasileiro S.A." },
      { bank_code: "623", bank_name: "Banco Pan S.A." },
      { bank_code: "204", bank_name: "Banco Bradesco Cartoes S.A." },
      { bank_code: "479", bank_name: "Banco ItauBank S.A." },
      { bank_code: "456", bank_name: "Banco MUFG Brasil S.A." },
      { bank_code: "464", bank_name: "Banco Sumitomo Mitsui Brasil S.A." },
      { bank_code: "613", bank_name: "Omni Banco S.A." },
      { bank_code: "652", bank_name: "Itaú Unibanco Holding Bm S.A." },
      { bank_code: "653", bank_name: "Banco Indusval S.A." },
      { bank_code: "069", bank_name: "Banco Crefisa S.A." },
      { bank_code: "370", bank_name: "Banco Mizuho S.A." },
      { bank_code: "249", bank_name: "Banco Investcred Unibanco S.A." },
      { bank_code: "318", bank_name: "Banco BMG S.A." },
      { bank_code: "626", bank_name: "Banco Ficsa S.A." },
      { bank_code: "270", bank_name: "Sagitur Cc Ltda" },
      { bank_code: "366", bank_name: "Banco Societe Generale Brasil" },
      { bank_code: "113", bank_name: "Magliano S.A." },
      { bank_code: "131", bank_name: "Tullett Prebon Brasil Cvc Ltda" },
      {
        bank_code: "011",
        bank_name: "C.Suisse Hedging-Griffo Cv S.A (Credit Suisse)",
      },
      { bank_code: "611", bank_name: "Banco Paulista" },
      { bank_code: "755", bank_name: "Bofa Merrill Lynch Bm S.A." },
      { bank_code: "089", bank_name: "Ccr Reg Mogiana" },
      { bank_code: "643", bank_name: "Banco Pine S.A." },
      { bank_code: "140", bank_name: "Easynvest – Título Cv S.A." },
      { bank_code: "707", bank_name: "Banco Daycoval S.A." },
      { bank_code: "288", bank_name: "Carol Dtvm Ltda" },
      { bank_code: "101", bank_name: "Renascença Dtvm Ltda" },
      { bank_code: "487", bank_name: "Deutsche Bank S.A (Banco Alemão)" },
      { bank_code: "233", bank_name: "Banco Cifra S.A." },
      {
        bank_code: "177",
        bank_name: "Guide Investimentos S.A. Corretora de Valores",
      },
      { bank_code: "633", bank_name: "Banco Rendimento S.A." },
      { bank_code: "218", bank_name: "Banco Bs2 S.A." },
      {
        bank_code: "292",
        bank_name: "BS2 Distribuidora De Títulos E Investimentos",
      },
      { bank_code: "169", bank_name: "Banco Olé Bonsucesso Consignado S.A." },
      { bank_code: "293", bank_name: "Lastro Rdv Dtvm Ltda" },
      { bank_code: "285", bank_name: "Frente Cc Ltda" },
      { bank_code: "080", bank_name: "B&T Cc Ltda" },
      { bank_code: "753", bank_name: "Novo Banco Continental S.A Bm" },
      { bank_code: "222", bank_name: "Banco Crédit Agricole Br S.A." },
      { bank_code: "754", bank_name: "Banco Sistema S.A." },
      { bank_code: "098", bank_name: "Credialiança Ccr" },
      { bank_code: "610", bank_name: "Banco VR S.A." },
      { bank_code: "712", bank_name: "Banco Ourinvest S.A." },
      { bank_code: "010", bank_name: "CREDICOAMO CRÉDITO RURAL COOPERATIVA" },
      { bank_code: "283", bank_name: "RB Capital Investimentos Dtvm Ltda" },
      { bank_code: "217", bank_name: "Banco John Deere S.A." },
      { bank_code: "117", bank_name: "Advanced Cc Ltda" },
      { bank_code: "336", bank_name: "Banco C6 S.A – C6 Bank" },
      { bank_code: "654", bank_name: "Banco A.J. Renner S.A." },
      { bank_code: "n/a", bank_name: "Banco Central do Brasil – Selic" },
      { bank_code: "n/a", bank_name: "Banco Central do Brasil" },
      { bank_code: "272", bank_name: "AGK Corretora de Câmbio S.A." },
      { bank_code: "n/a", bank_name: "Secretaria do Tesouro Nacional – STN" },
      {
        bank_code: "330",
        bank_name: "Banco Bari de Investimentos e Financiamentos S.A.",
      },
      { bank_code: "362", bank_name: "CIELO S.A." },
      {
        bank_code: "322",
        bank_name:
          "Cooperativa de Crédito Rural de Abelardo Luz – Sulcredi/Crediluz",
      },
      {
        bank_code: "350",
        bank_name:
          "Cooperativa De Crédito Rural De Pequenos Agricultores E Da Reforma Agrária Do Ce",
      },
      {
        bank_code: "091",
        bank_name:
          "Central De Cooperativas De Economia E Crédito Mútuo Do Estado Do Rio Grande Do S",
      },
      {
        bank_code: "379",
        bank_name:
          "COOPERFORTE – Cooperativa De Economia E Crédito Mútuo Dos Funcionários De Instit",
      },
      {
        bank_code: "378",
        bank_name: "BBC LEASING S.A. – Arrendamento Mercantil",
      },
      {
        bank_code: "360",
        bank_name:
          "TRINUS Capital Distribuidora De Títulos E Valores Mobiliários S.A.",
      },
      {
        bank_code: "084",
        bank_name: "UNIPRIME NORTE DO PARANÁ – COOPERATIVA DE CRÉDITO LTDA",
      },
      {
        bank_code: "n/a",
        bank_name: "Câmara Interbancária de Pagamentos – CIP – LDL",
      },
      { bank_code: "387", bank_name: "Banco Toyota do Brasil S.A." },
      {
        bank_code: "326",
        bank_name: "PARATI – CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
      },
      {
        bank_code: "315",
        bank_name: "PI Distribuidora de Títulos e Valores Mobiliários S.A.",
      },
      {
        bank_code: "307",
        bank_name:
          "Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.",
      },
      { bank_code: "296", bank_name: "VISION S.A. CORRETORA DE CAMBIO" },
      {
        bank_code: "382",
        bank_name:
          "FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L",
      },
      {
        bank_code: "097",
        bank_name: "Credisis – Central de Cooperativas de Crédito Ltda.",
      },
      {
        bank_code: "016",
        bank_name:
          "COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI",
      },
      {
        bank_code: "299",
        bank_name: "SOROCRED   CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
      },
      {
        bank_code: "359",
        bank_name: "ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A",
      },
      {
        bank_code: "391",
        bank_name: "COOPERATIVA DE CRÉDITO RURAL DE IBIAM – SULCREDI/IBIAM",
      },
      { bank_code: "368", bank_name: "Banco CSF S.A." },
      { bank_code: "259", bank_name: "MONEYCORP BANCO DE CÂMBIO S.A." },
      { bank_code: "364", bank_name: "GERENCIANET S.A." },
      {
        bank_code: "014",
        bank_name: "STATE STREET BRASIL S.A. – BANCO COMERCIAL",
      },
      { bank_code: "081", bank_name: "Banco Seguro S.A." },
      {
        bank_code: "384",
        bank_name:
          "GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO",
      },
      { bank_code: "088", bank_name: "BANCO RANDON S.A." },
      {
        bank_code: "319",
        bank_name: "OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
      },
      {
        bank_code: "274",
        bank_name:
          "MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT",
      },
      { bank_code: "095", bank_name: "Travelex Banco de Câmbio S.A." },
      { bank_code: "332", bank_name: "Acesso Soluções de Pagamento S.A." },
      {
        bank_code: "325",
        bank_name: "Órama Distribuidora de Títulos e Valores Mobiliários S.A.",
      },
      {
        bank_code: "331",
        bank_name:
          "Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.",
      },
      { bank_code: "396", bank_name: "HUB PAGAMENTOS S.A." },
      { bank_code: "309", bank_name: "CAMBIONET CORRETORA DE CÂMBIO LTDA." },
      { bank_code: "313", bank_name: "AMAZÔNIA CORRETORA DE CÂMBIO LTDA." },
      {
        bank_code: "377",
        bank_name:
          "MS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA",
      },
      {
        bank_code: "321",
        bank_name:
          "CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT",
      },
      {
        bank_code: "383",
        bank_name: "BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAMENTOS LTDA.",
      },
      {
        bank_code: "324",
        bank_name: "CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.",
      },
      { bank_code: "380", bank_name: "PICPAY SERVICOS S.A." },
      {
        bank_code: "343",
        bank_name:
          "FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.",
      },
      {
        bank_code: "349",
        bank_name: "AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
      },
      {
        bank_code: "374",
        bank_name: "REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
      },
      {
        bank_code: "n/a",
        bank_name: "B3 SA – Brasil, Bolsa, Balcão – Segmento Cetip UTVM",
      },
      {
        bank_code: "n/a",
        bank_name: "Câmara Interbancária de Pagamentos – CIP C3",
      },
      {
        bank_code: "352",
        bank_name: "TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
      },
      { bank_code: "329", bank_name: "QI Sociedade de Crédito Direto S.A." },
      {
        bank_code: "342",
        bank_name: "Creditas Sociedade de Crédito Direto S.A.",
      },
      { bank_code: "397", bank_name: "LISTO SOCIEDADE DE CRÉDITO DIRETO S.A." },
      { bank_code: "355", bank_name: "ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A." },
      {
        bank_code: "367",
        bank_name: "VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
      },
      {
        bank_code: "373",
        bank_name: "UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.",
      },
      {
        bank_code: "408",
        bank_name: "BÔNUSCRED SOCIEDADE DE CRÉDITO DIRETO S.A.",
      },
      { bank_code: "404", bank_name: "SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A." },
      { bank_code: "403", bank_name: "CORA SOCIEDADE DE CRÉDITO DIRETO S.A." },
      {
        bank_code: "306",
        bank_name:
          "PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
      },
      {
        bank_code: "174",
        bank_name: "PEFISA S.A. – CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
      },
      {
        bank_code: "354",
        bank_name:
          "NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES",
      },
      {
        bank_code: "n/a",
        bank_name:
          "BMF Bovespa S.A. – Bolsa de Valores, Mercadorias e Futuros – Camara BMFBOVESPA",
      },
      { bank_code: "630", bank_name: "Banco Smartbank S.A." },
      { bank_code: "393", bank_name: "Banco Volkswagen S.A." },
      { bank_code: "390", bank_name: "BANCO GM S.A." },
      { bank_code: "381", bank_name: "BANCO MERCEDES-BENZ DO BRASIL S.A." },
      { bank_code: "626", bank_name: "BANCO C6 CONSIGNADO S.A." },
      {
        bank_code: "755",
        bank_name: "Bank of America Merrill Lynch Banco Múltiplo S.A.",
      },
      { bank_code: "089", bank_name: "CREDISAN COOPERATIVA DE CRÉDITO" },
      {
        bank_code: "363",
        bank_name: "SOCOPA SOCIEDADE CORRETORA PAULISTA S.A.",
      },
      {
        bank_code: "365",
        bank_name: "SOLIDUS S.A. CORRETORA DE CAMBIO E VALORES MOBILIARIOS",
      },
      { bank_code: "281", bank_name: "Cooperativa de Crédito Rural Coopavel" },
      { bank_code: "654", bank_name: "BANCO DIGIMAIS S.A." },
      {
        bank_code: "371",
        bank_name: "WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.",
      },
      { bank_code: "289", bank_name: "DECYSEO CORRETORA DE CAMBIO LTDA." },
    ];

    const banks = totalBanks.filter((objBank) =>
      apiBanks.includes(objBank.bank_code)
    );

    return (
      <div>
        <NavBar />
        <br />
        <Wrapper>
          <p>Editar Perfil</p>
          <br />
          {this.state.error && <p>{this.state.error}</p>}

          <Autocomplete
            size="small"
            style={{ width: 250 }}
            options={banks}
            getOptionLabel={(option) => option.bank_name}
            onChange={(e, option) =>
              this.setState({ bank_code: option.bank_code })
            }
            renderOption={(option) => (
              <React.Fragment>
                ({option.bank_code}) {option.bank_name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Banco"
                variant="outlined"
                // inputProps={{
                //     ...params.inputProps,
                //     autoComplete: 'new-password', // disable autocomplete and autofill
                // }}
              />
            )}
          />
          <br></br>
          <TextField
            style={{ width: 250 }}
            type="number"
            size="small"
            id="outlined-basic"
            label="Agência (sem dígito)"
            variant="outlined"
            value={agencia}
            onChange={(e) => this.setState({ agencia: e.target.value })}
          />
          <br></br>
          <TextField
            style={{ width: 250 }}
            type="number"
            size="small"
            id="outlined-basic"
            label="Dígito da Agência (se houver)"
            variant="outlined"
            value={agenciaDV}
            onChange={(e) => this.setState({ agenciaDV: e.target.value })}
          />
          <br></br>
          <TextField
            style={{ width: 250 }}
            type="number"
            size="small"
            id="outlined-basic"
            label="Conta (sem dígito)"
            variant="outlined"
            value={conta}
            onChange={(e) => this.setState({ conta: e.target.value })}
          />
          <br></br>
          <TextField
            style={{ width: 250 }}
            type="number"
            size="small"
            id="outlined-basic"
            label="Dígito da Conta"
            variant="outlined"
            value={conta_dv}
            onChange={(e) => this.setState({ conta_dv: e.target.value })}
          />
          <br></br>
          <TextField
            style={{ width: 250 }}
            type="number"
            size="small"
            id="outlined-basic"
            label="Documento (CPF)"
            variant="outlined"
            value={document_number}
            onChange={(e) => this.setState({ document_number: e.target.value })}
          />
          <br></br>
          <TextField
            type="string"
            style={{ backgroundColor: "#e9ecef", width: 250 }}
            size="small"
            id="outlined-basic"
            placeholder="Conta Corrente"
            disabled
            color="#495057"
            variant="outlined"
          />
          <br></br>

          <Button style={{ backgroundColor: "#000" }} onClick={this.finalizar}>
            Finalizar
          </Button>

          <br></br>
          <p style={{ textAlign: "center", fontSize: "1.2em", backgroundColor: "#FFF", border: "1px solid", borderRadius: "10px", marginTop: "20px"}}>
            Caso sua instituição bancária não esteja na lista, por favor entre em contato conosco pelo e-mail: contato@uinstock.net
          </p>
        </Wrapper>
      </div>
    );
  }
}

export default withRouter(EditProfile);
