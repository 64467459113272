import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { isAuthenticated, getToken } from "../api/auth";
import api from "../api";
// import { ConsumerGallery, UserGallery, AdminGallery } from '../pages'

const CustomRoute = ({ component: Component, condition, ...rest }) => {
    const [loading, setLoading] = React.useState(true);
    const [typeUser, settypeUser] = React.useState(false);

    React.useEffect(() => {
        const token = getToken();
        api.typeUser(token).then(response => {
            settypeUser(response.data.typeUser)
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            console.log(err);
        });
    });

    switch(condition) {

        case "Admin":
            return (<Route
                {...rest}
                render={props =>
                    (typeUser === "Admin") ? (
                        <Component {...props} />
                    ) : loading ? (
                        <div>LOADING...</div>
                    ) : (
                        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                    )
                }
            />)
        case "Restrict":
            return (<Route
                {...rest}
                render={props =>
                    (typeUser === "User" || typeUser === "Admin") ? (
                        <Component {...props} />
                    ) : loading ? (
                        <div>LOADING...</div>
                    ) : (
                        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                    )
                }
            />)

        case "Private":
            return (<Route
                {...rest}
                render={props =>
                    (typeUser === "User" || typeUser === "Admin" || typeUser === "Consumer") ? (
                        <Component {...props} />
                    ) : loading ? (
                        <div>LOADING...</div>
                    ) : (
                        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                    )
                }
            />)
        case "Consumer":
            return (<Route
                {...rest}
                render={props =>
                    (typeUser === "Consumer") ? (
                        <Component {...props} />
                    ) : loading ? (
                        <div>LOADING...</div>
                    ) : (
                        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                    )
                }
            />)
        case "User":
            return (<Route
                {...rest}
                render={props =>
                    (typeUser === "User") ? (
                        <Component {...props} />
                    ) : loading ? (
                        <div>LOADING...</div>
                    ) : (
                        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                    )
                }
            />)
        default:
            return (<Route
                {...rest}
                render={props =>
                    isAuthenticated() ? (
                        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                    ) : (
                        <Component {...props} />
                    )
                }
            />)
    }
}


export default CustomRoute;