import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "react-table/react-table.css";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rootDesktop: {
    padding: "2px 4px",
    display: "none",
    alignItems: "center",
    width: 400,
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  rootMobile: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(2),
    width: 250,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 250,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function SearchBar({
  classes,
  onCapture,
  categories,
  search,
  category,
  onCategory,
}) {
  const classesNavBar = useStyles();
  const component = (
    <Grid
      style={{ width: "100%" }}
      container
      justify="space-between"
      alignItems="center"
    >
      <FormControl
        classes={{
          root: classesNavBar.rootDesktop,
        }}
      >
        <InputLabel style={{ marginLeft: 16 }}>
          Selecione uma categoria
        </InputLabel>
        <Select
          style={{ width: "100%" }}
          value={category}
          onChange={onCategory}
        >
          <MenuItem value="">
            <em>Todos os Marcadores</em>
          </MenuItem>
          {categories && categories.length > 0
            ? categories.map((c) =>
                c.title === "Sem categoria" ? null : (
                    <MenuItem value={c._id}>{c.title}</MenuItem>
                )
              )
            : null}
        </Select>
      </FormControl>

      <FormControl
        classes={{
          root: classesNavBar.rootMobile,
        }}
        style={{ marginBottom: 16 }}
      >
        <Select
          style={{ width: "100%" }}
          value={category}
          onChange={onCategory}
        >
          <MenuItem value="">
            <em>Todos os Marcadores</em>
          </MenuItem>
          {categories && categories.length > 0
            ? categories.map((c) =>
                c.title === "Sem categoria" ? null : (
                    <MenuItem value={c._id}>{c.title}</MenuItem>
                )
              )
            : null}
        </Select>

      </FormControl>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <Paper component="form" className={classesNavBar.rootDesktop}>
          <IconButton disabled>
            <SearchIcon />
          </IconButton>
          <Divider className={classes.divider} orientation="vertical" />
          <InputBase
            placeholder="Digite aqui a palavra chave que busca"
            classes={{
              root: classesNavBar.rootDesktop,
              input: classesNavBar.input,
            }}
            inputProps={{
              "aria-label": "Digite aqui a palavra chave que busca",
            }}
            value={search}
            // onChangeCapture={(e) => this.setState({search: e.target.value})}
            // onChangeCapture={(e) => onCapture(e)}
            onChange={(e) => onCapture(e)}
          />
        </Paper>

        <Paper component="form" className={classesNavBar.rootMobile}>
          <IconButton disabled>
            <SearchIcon />
          </IconButton>
          <Divider className={classes.divider} orientation="vertical" />
          <InputBase
            placeholder="Buscar palavra chave"
            classes={{
              root: classesNavBar.root,
              input: classesNavBar.input,
            }}
            inputProps={{
              "aria-label": "Digite aqui a palavra chave que busca",
            }}
            value={search}
            // onChangeCapture={(e) => this.setState({search: e.target.value})}
            onChangeCapture={(e) => onCapture(e)}
          />
        </Paper>
      </div>
    </Grid>
  );

  return component;
}
