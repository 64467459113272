import React, { Component } from "react";
import { NavBar, SalesInformation } from "../components";
import { DataGrid } from '@material-ui/data-grid';
import api from "../api";
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';

const columns = [
    { field: 'date', headerName: 'Data da venda', width: 150 },
    { field: 'title',headerName: 'Nome da foto', width: 200, renderCell: (params) => (<a href={`/photo/` + params.row.photoId}>{params.value}</a>) },
    { field: 'isCredit', headerName: 'Crédito?', width: 110 },
    { field: 'originalPrice', headerName: 'Valor da foto', width: 140 },
    { field: 'price', headerName: 'Valor da venda', width: 150 },
    { field: 'commission', headerName: 'Comissão', width: 130 },
]
//  adicionar "valor da foto", "valor da venda", "creditos" (sim ou não)
const TodayDate = new Date()

class AdminPainel extends Component {
    state = {
        myPhotos: [],
        monthSales: [],
        photosPeriod: [],
        qtdMonth: 0,
        monthDeposit: '',
        yearDeposit: '',
        beginDate: null,
        finalDate: null,
        periodSelected: 'all',
        error: null,
        loading: true,
        totalAmount: 0,
        monthAmount: 0,
        periodAmount: 0
    };

    handleSelectPeriod = (event) => {
        this.setState({
            periodSelected: event.target.value,
            error: null
        })
    };
      
    componentDidMount = async () => {
        await api.getMySales().then(res => {
            this.setState({
                myPhotos: res.data.mySales,
                monthSales: res.data.monthSales,
                qtdMonth: res.data.qtdMonth,
                totalAmount: res.data.totalAmount,
                monthAmount: res.data.monthAmount,
                loading: false
            })
        })
        if (TodayDate.getMonth() + 1 < 12) {
            this.setState({
                monthDeposit: TodayDate.getMonth() + 2,
                yearDeposit: TodayDate.getYear() + 1900
            })
        } else {
            this.setState({
                monthDeposit: '01',
                yearDeposit: TodayDate.getYear() + 1901
            })
        }
    }
    
    handlePhotosPeriod = () => {
        if (!this.state.beginDate || !this.state.finalDate) {
            this.setState({
                error: 'Selecione as datas de início e final do período'
            })
            return ''
        }
        else {
            var photos = []
            var cont = 0
            var dateBegin = this.state.beginDate
            var dateFinal = this.state.finalDate
            var mount = 0
            this.state.myPhotos && this.state.myPhotos.map(function(sale) {
                var date = sale.date.split(",")[0].split("/")
                date = new Date(date[2] + "/" + date[1] + "/" + date[0])
                console.log(date)
                if (date >= dateBegin && date <= dateFinal) {
                    cont = cont + 1
                    photos.push(sale)
                    mount = mount + Number(sale.price.substr(3,sale.price.length - 6)) * 100
                }
                return ''
            })
            this.setState({
                error: null,
                photosPeriod: photos,
                periodAmount: mount
            })
            return ''
        }
    }

    gridPhotos = (myPhotos, columns) => {
        return (
            this.state.periodSelected === 'period' ? 
                <div style={{ height: '80vh', width: '100%' }}>
                    {
                        this.state.photosPeriod ? (
                            <DataGrid rows={this.state.photosPeriod} columns={columns}/>
                        ) : ""
                    }
                </div>
            : (  this.state.periodSelected === 'month' ? 
                    <div style={{ height: '80vh', width: '100%' }}>
                        {
                            this.state.monthSales ? (
                                <DataGrid rows={this.state.monthSales} columns={columns}/>
                            ) : ""
                        }
                    </div>
                : <div style={{ height: '80vh', width: '100%' }}>
                    {
                        myPhotos ? (
                            <DataGrid rows={myPhotos} columns={columns}/>
                        ) : ""
                    }
                </div>
            )
        )
    }

    render() 
    {
        const { myPhotos } = this.state;
        const { periodSelected } = this.state;
        const { qtdMonth } = this.state;
        const { totalAmount, monthAmount, periodAmount } = this.state;
        return (
            <div>
                <NavBar />
                <div className="text-center mt-5" style={{display: "flex", justifyContent: "center", flexDirection: "column", width: "100vw"}}>
                    <h1>Minhas vendas</h1>
                </div><br />
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100vw"}}>
                    <strong>Selecione a seguir uma opção de busca:</strong>
                    <FormControl style={{marginLeft: '16px'}}>
                        <RadioGroup row value={periodSelected} onChange={e => this.handleSelectPeriod(e)}>
                            <FormControlLabel value="all" control={<Radio />} label="Todas as vendas" />
                            <FormControlLabel value="month" control={<Radio />} label="Vendas do mês" />
                            <FormControlLabel value="period" control={<Radio />} label="Vendas de um período" />
                        </RadioGroup>
                    </FormControl><br />
                    {this.state.loading ? 'Aguarde um instante, carregando informações...' : ''}
                    {
                        periodSelected === 'period' ? (
                            <div style={{display: "flex", alignItems: "center", flexDirection: "column", width: "100vw"}}>
                                <strong>Selecione o período:</strong>
                                <div style={{display: "flex", alignItems: "center", flexDirection: "row", justifyContent: 'center', flexWrap: 'wrap'}}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            style={{width: '200px', marginRight: '6px', marginLeft: '6px', marginTop: '12px'}}
                                            autoOk
                                            variant="inline"
                                            inputVariant="outlined"
                                            label="Início"
                                            format="dd/MM/yyyy"
                                            placeholder="dd/mm/yyyy"
                                            value={this.state.beginDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => this.setState({beginDate: date})}
                                        />
                                       <KeyboardDatePicker
                                            style={{width: '200px', marginLeft: '6px', marginRight: '6px', marginTop: '12px'}}
                                            autoOk
                                            variant="inline"
                                            inputVariant="outlined"
                                            label="Final"
                                            format="dd/MM/yyyy"
                                            placeholder="dd/mm/yyyy"
                                            value={this.state.finalDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => this.setState({finalDate: date})}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <Button style={{marginTop: '12px'}} size="small" color="primary" variant="contained" onClick={() => this.handlePhotosPeriod()}>
                                    Filtrar
                                </Button>
                                <br />
                                {
                                    this.state.error !== null ? <span style={{color: "red"}}>{this.state.error}</span> : null
                                }
                                <br />
                            </div>
                        ) : null
                    }
                </div>
                {
                    this.state.loading === false ? 
                    <SalesInformation
                        monthDeposit={this.state.monthDeposit}
                        yearDeposit={this.state.yearDeposit}
                        gridPhotos={this.gridPhotos(myPhotos, columns)}
                        quantityAllPhotos={myPhotos.length}
                        qunatityMonthPhotos={qtdMonth}
                        quantityPhotosPeriod={this.state.photosPeriod.length}
                        periodSelected={periodSelected}
                        totalAmount={totalAmount}
                        monthAmount={monthAmount}
                        periodAmount={periodAmount}
                    /> : ''
                }
            </div>
        );
    }
}

export default AdminPainel;
