import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
        margin: theme.spacing(0),
        },
    },
    buttons: {
        margin: theme.spacing(1),
        backgroundColor: "#ff5100",
        color: "white",
        '&:hover': {
            backgroundColor: '#ff5100',
            color: 'white',
        },
    },
    error: {
        color: "red",
        fontSize: "12px"
    },
}));


export default function LoginAndSignupButtons({handleClickOpentoLogin, handleClickOpentoSignup, open}) {
    const classes = useStyles();

    // const handleClickOpentoLogin = () => {
    //     setOpen(<DialogLoginAndSignup {...dialogParams} openButton={"login"} />);
    // };
    
    // const handleClickOpentoSignup = () => {
    //     setOpen(<DialogLoginAndSignup {...dialogParams} openButton={"signup"} />);
    // };

    const component = (
        <div className={classes.root}>
            <Button onClick={handleClickOpentoLogin} size="small" className={classes.buttons} variant="contained">
                Entrar
            </Button>
            <Button onClick={handleClickOpentoSignup} size="small" className={classes.buttons} variant="contained">
                Cadastrar
            </Button>
            {open}
        </div>
    )

    return component
}