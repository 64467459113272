import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import api from "../api";
import { login } from '../api/auth'
import { NavBar } from '../components'

class Confirmation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: ""
        }
    };

    componentDidMount = async () => {
        const token = this.props.location.pathname.split('/')[2]
        await api.confirmation(token).then(res => {
            login(token)
            this.props.history.push('/')
        }).catch(err => {
            this.setState({error: "Ocorreu um erro, por favor tente novamente!"})
        })
    }

    render() {
        return (
            <div>
                <NavBar />
                <br/>
                {this.state.error}
            </div>
        );
    }
}

export default withRouter(Confirmation);
