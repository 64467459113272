import React, { Component } from "react";
import api from "../api";

import "react-table/react-table.css";
import {
  NavBarLandingConsumer,
  SearchBar,
  styles,
  LandingStore,
} from "../components";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { searchUrlString, categoryUrlString } from "../utils/urlSearch";

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [],
      columns: [],
      isLoading: true,
      raised: [],
      search: searchUrlString(),
      type: "User",
      NavBarLandingConsumer: <NavBarLandingConsumer />,
      navigation: 0,
      open: null,
      anchorEl: null,
      category: "",
      categories: [],
    };

    this.toggleRaised = this.toggleRaised.bind(this);
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true });

    await api.landingConsumer().then((response) => {
      this.setState({
        photos: response.data.photos,
        raised: response.data.photos.photos.map((photo) => ({
          _id: photo._id,
          raise: false,
        })),
        type: response.data.type,
      });
    });

    if (this.state.isLoading)
      await api.getCategories().then((response) => {
        var cUrl = categoryUrlString();
        var selectedCategory = response.data.categories.find(
          (c) => c.title === cUrl
        );
        this.setState({
          categories: response.data.categories,
          category: selectedCategory ? selectedCategory._id : "",
        });

        this.setState({ isLoading: false });
      });

    this.setState({ isLoading: false });
  };

  toggleRaised = (photo) => {
    const raise = this.state.raised.findIndex((x) => x._id === photo._id);
    let raised = this.state.raised;
    raised[raise].raise = !raised[raise].raise;
    this.setState({ raised: raised });
  };

  render() {
    const { photos } = this.state;
    const { classes } = this.props;

    const search = (photo, tag) => {
      if (!photo) return false;
      const photoTags = [...photo.tags, photo.title];
      return photoTags
        .map((photoTag) =>
          this.state.category !== ""
            ? photoTag.toLowerCase().includes(tag.toLowerCase()) &&
              photo.category &&
              photo.category.includes(this.state.category)
            : photoTag.toLowerCase().includes(tag.toLowerCase())
        )
        .includes(true);
    };

    const onCapture = (e) => {
      this.setState({ search: e.target.value });
    };

    const onCategory = (e) => {
      this.setState({
        category: e.target.value,
      });
    };

    const filterLogic = (photo) => {
      switch (this.state.navigation) {
        case 1:
          photo = photo.status === "Authorized" ? photo : null;
          return search(photo, this.state.search);
        case 2:
          photo = photo.status === "Waiting" ? photo : null;
          photo =
            photo && photo.declaration && photo.declaration.url ? photo : null;
          return search(photo, this.state.search);
        case 3:
          photo = photo.status === "Unauthorized" ? photo : null;
          return search(photo, this.state.search);
        case 4:
          photo = photo.declaration && photo.declaration.url ? null : photo;
          return search(photo, this.state.search);
        default:
          return search(photo, this.state.search);
      }
    };

    const renderMenu = (
      <Menu
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        id={"primary-search-account-menu-mobile"}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={this.state.open}
        onClose={() => this.setState({ open: null })}
      >
        <MenuItem onClick={() => this.setState({ navigation: 0 })}>
          <Typography variant="body2">Todas</Typography>
        </MenuItem>
        <MenuItem onClick={() => this.setState({ navigation: 1 })}>
          <Typography variant="body2">Aprovadas</Typography>
        </MenuItem>
        <MenuItem onClick={() => this.setState({ navigation: 2 })}>
          <Typography variant="body2">A Revisar</Typography>
        </MenuItem>
        <MenuItem onClick={() => this.setState({ navigation: 3 })}>
          <Typography variant="body2">Reprovadas</Typography>
        </MenuItem>
        <MenuItem onClick={() => this.setState({ navigation: 4 })}>
          <Typography variant="body2">Sem Autorização</Typography>
        </MenuItem>
      </Menu>
    );

    return (
      <div>
        <NavBarLandingConsumer />
        <br />
        {window.location.pathname === "/landing-consumer" ? (
          <div className={classes.search}>
            <SearchBar
              search={this.state.search}
              category={this.state.category}
              classes={classes}
              onCapture={onCapture}
              categories={this.state.categories}
              onCategory={onCategory}
            />
            {renderMenu}
          </div>
        ) : (
          ""
        )}
        <br />
        <LandingStore
          photos={photos}
          filterLogic={filterLogic}
          history={this.props.history}
          toggleRaised={this.toggleRaised}
          raised={this.state.raised}
        />
        {this.state.isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <br />
            <CircularProgress />
          </div>
        ) : (
          ""
        )}
        {!this.state.isLoading && !this.state.photos.photos[0] ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            Nenhuma foto disponível na loja.
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Gallery);
