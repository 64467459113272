import React, { Component } from "react";
import { NavBar } from "../components";
import withStyles from "@material-ui/core/styles/withStyles";
import { DataGrid } from "@material-ui/data-grid";
import api from "../api";
import Button from "@material-ui/core/Button";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

const styles = (theme) => ({
  paper: {
    position: "absolute",
    width: "80vw",
    height: "80vh",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const columns = [
  { field: "name", headerName: "Nome", width: 200 },
  { field: "email", headerName: "Email", width: 400 },
  { field: "phone", headerName: "Telefone", width: 200 },
  { field: "type", headerName: "Usuário", width: 200 },
];

class AdminPainel extends Component {
  state = {
    users: null,
    open: false,
    selection: [],
    copy: "",
    editCategory: true,
    newCategory: null,
    editedCategory: null,
    category: null,
    categories: [],
  };

  componentDidMount = async () => {
    await api.getUsers().then((res) => {
      res.data.users.map((user) =>
        user.type === "User"
          ? (user.type = "Fotógrafo")
          : user.type === "Admin"
          ? (user.type = "Admin")
          : (user.type = "Cliente")
      );
      this.setState({
        users: res.data.users,
      });
    });

    await api.getCategories().then((response) => {
      this.setState({
        categories: response.data.categories,
      });
    });
  };

  handleListCategories = (event) => {
    this.setState({
      category: event.target.value,
    });
  };

  handleNewCategory = (event) => {
    this.setState({
      newCategory: event.target.value,
    });
  };

  handleEditedCategory = (event) => {
    this.setState({
      editedCategory: event.target.value,
    });
  };

  updateCategory = async (tmpCategory, category = undefined) => {
    if (category) {
      await api
        .updateCategory({
          id: category,
          title: tmpCategory,
        })
        .then((_response) => window.location.reload());
    } else {
      await api
        .updateCategory({
          title: tmpCategory,
        })
        .then((_response) => window.location.reload());
    }
  };

  handleEditCategory = async () => {
    await this.updateCategory(this.state.editedCategory, this.state.category);
  };

  handleCreateEdition = async () => {
    await this.updateCategory(this.state.newCategory);
  };

  render() {
    const { users } = this.state;

    const copyEl = (e, field) => {
      const el = document.createElement("textarea");
      const content = this.state.selection.map((el) => el[field]);
      el.value = content.join(",");
      if (el.value !== "") {
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        this.setState({ copy: "Copiado com Sucesso!" });
      }
    };

    const buttonTemplate = (label, field) => (
      <Button
        variant="contained"
        style={{
          backgroundColor: "#000",
          color: "#FFFFFF",
          width: 300,
          alignSelf: "center",
          margin: "16px",
        }}
        onClick={(e) => copyEl(e, field)}
      >
        {label}
      </Button>
    );

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "100vw",
        }}
      >
        <NavBar />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: "400px",
          }}
        >
          {this.state.editCategory ? (
            <Button
              onClick={() =>
                this.setState({
                  editCategory: false,
                })
              }
            >
              Criar Categoria
            </Button>
          ) : (
            <Button
              onClick={() =>
                this.setState({
                  editCategory: true,
                })
              }
            >
              Editar Categoria
            </Button>
          )}
          {this.state.editCategory ? (
            <FormControl
              variant="outlined"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "8px",
                marginRight: "8px",
                marginBottom: "8px",
              }}
            >
              <InputLabel>Editar Categoria</InputLabel>
              <Select
                value={this.state.category}
                onChange={this.handleListCategories}
              >
                {this.state.categories.map((category) => (
                  <MenuItem value={category._id}>{category.title}</MenuItem>
                ))}
              </Select>
              {this.state.category ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <TextField
                    variant="outlined"
                    label="Nome da Categoria"
                    value={this.state.editedCategory}
                    onChange={this.handleEditedCategory}
                  />
                  <Button onClick={this.handleEditCategory}>Editar</Button>
                </div>
              ) : null}
            </FormControl>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField
                variant="outlined"
                label="Nome da Categoria"
                value={this.state.newCategory}
                onChange={this.handleNewCategory}
              />
              <Button onClick={this.handleCreateEdition}>Criar</Button>
            </div>
          )}
        </div>
        <br />
        <div style={{ height: "80vh", width: "100%" }}>
          {users ? (
            <DataGrid
              rows={users}
              columns={columns}
              checkboxSelection
              onSelectionModelChange={(newSelection) => {
                this.setState({
                  selection: users.filter((el) =>
                    newSelection.selectionModel.includes(el.id)
                  ),
                  copy: "",
                });
              }}
            />
          ) : (
            ""
          )}
        </div>
        <div>
          {buttonTemplate("Copiar Nomes Selecionados", "name")}
          {buttonTemplate("Copiar Emails Selecionados", "email")}
          {buttonTemplate("Copiar Telefones Selecionados", "phone")}
          {this.state.copy}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AdminPainel);
