import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import { login } from "../api/auth";
import {Link} from "react-router-dom";
import Logo from '../assets/uin-stock-logo-plataforma-positivo.svg';
import { DialogUser } from "../components";
import Divider from "@material-ui/core/Divider"
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    buttons: {
        margin: theme.spacing(1),
        backgroundColor: "#ff5100",
        color: "white",
        '&:hover': {
            backgroundColor: '#ff5100',
            color: 'white',
        },
    },
    dividerColor: {
        backgroundColor: '#ff5100',
      },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconButtonBar: {
        marginLeft: theme.spacing(4),
        height: 20,
        width: 20
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default function PrimarySearchAppBar() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    // const [open, setOpen] = React.useState(false);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const history = useHistory();

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const dialogParams = {
    //     handleClose: handleClose,
    //     urlLogin: '/store',
    //     urlSignup: '/store'
    // }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    // const handleClickOpentoLogin = () => {
    //     setOpen(<DialogLoginAndSignup {...dialogParams} openButton={"login"} />);
    // };
    
    // const handleClickOpentoSignup = () => {
    //     setOpen(<DialogLoginAndSignup {...dialogParams} openButton={"signup"} />);
    // };

    // const signUpConsumerButtons = (
    //     <Button onClick={() => { history.push('/signup-consumer') }} size="small" className={classes.buttons} variant="contained">
    //         Cadastrar
    //     </Button>
    // )

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={login}>Login</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';


    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >   
            <MenuItem onClick={() => { history.push('/login') }}>Entrar</MenuItem>
            <MenuItem onClick={() => { history.push('/signup-consumer') }}>Cadastrar</MenuItem>
        </Menu>
    );

    const component = (
        <div className={classes.grow}>
            <AppBar position="static" elevation={0} style={{backgroundColor: "white"}}>
                <Toolbar>
                    <Typography className={classes.title} variant="h6" noWrap>
                        <Link onClick={() => window.location.href = "https://uinstock.net"} style={{color: "#FFF"}}>
                            <img style={{height: '28px'}} src={ Logo } alt="Logo" />
                        </Link>
                    </Typography>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        { <DialogUser></DialogUser> }
                        <Button onClick={() => { history.push('/login') }} size="small" className={classes.buttons} variant="contained">
                            Entrar
                        </Button>
                        <Button onClick={() => { history.push('/signup-consumer') }} size="small" className={classes.buttons} variant="contained">
                            Cadastrar
                        </Button>
                        {/* { <LoginAndSignupButtons handleClickOpentoSignup={handleClickOpentoSignup} handleClickOpentoLogin={handleClickOpentoLogin} open={open} /> } */}
                    </div>
                    <div className={classes.sectionMobile}>
                        { <DialogUser></DialogUser> }
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="black"
                        >
                            <MoreIcon />
                        </IconButton>
                        {/* {
                            <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon />
                            </IconButton>
                        } */}

                    </div>
                </Toolbar>
            </AppBar>
            <Divider variant="middle" className={classes.dividerColor} style={{marginLeft: "16px", marginRight: "16px", width: "97%"}}/>
            {renderMobileMenu}
            {renderMenu}
        </div>
    )

    return component;
}