import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { login } from '../api/auth'
import api from "../api";
import { NavBar } from '../components'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const Wrapper = styled.div.attrs({
    className: 'form-group',
})`
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
`

const InputText = styled.input.attrs({
    className: 'form-control',
})`
margin-bottom: 24px;
`

const Button = styled.button.attrs({
    className: `btn btn-primary`,
})`
`

class Login extends Component {
    state = {
        email: "",
        password: "",
        error: "",
        open: false
    };

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleSignUpConsumer = () => {
        window.location.href = "/signup-consumer"
    }

    handleSignUpUser = () => {
        window.location.href = "/signup"
    }

    handleLogin = async () => {
        const { email, password } = this.state
        const payload = {
            login: email,
            password: password
        }

        if (!email || !password) {
            this.setState({ error: "Preencha todos os dados para logar" });
        } else {
            try {
                await api.login(payload).then(res => {
                    login(res.data.token);
                })
            } catch (err) {
                this.setState({ error: "Ocorreu um erro ao logar sua conta" });
            }
        }
    };

    render() {
        const { email, password } = this.state

        return (
            <div>
                <NavBar />
                <br/>
                <Wrapper>
                    <p>Login</p>
                    <br/>
                    {this.state.error && <p>{this.state.error}</p>}
                    <InputText
                        type="text"
                        placeholder="Endereço de e-mail"
                        value={email}
                        onChange={e => this.setState({ email: e.target.value })}
                    />
                    <InputText
                        type="password"
                        placeholder="Senha"
                        value={password}
                        onChange={e => this.setState({ password: e.target.value })}
                    />
                    <Link to="/recover">Esqueci a Senha</Link>
                    <Button style={{marginTop: '16px', backgroundColor: "#ff5100", borderColor: "#ff5100"}} onClick={this.handleLogin}>Logar</Button>
                    <hr />
                    <hr />
                    Não tenho conta ainda: 
                    <Button style={{marginTop: '16px'}} onClick={() => this.setState({open: true})}>Cadastrar</Button>
                    {/* <Link to="/signup">Cadastrar</Link>
                    <Link to="/recover">Esqueci a Senha</Link> */}
                    <Dialog onClose={this.handleClose} open={this.state.open}>
                        <DialogTitle id="simple-dialog-title"><span style={{color: 'black'}} >Quero cadastrar como:</span></DialogTitle>
                        <Button style={{margin: '16px'}} onClick={this.handleSignUpUser}>Fotógrafo</Button>
                        <Button style={{margin: '16px'}} onClick={this.handleSignUpConsumer}>Consumidor</Button>
                    </Dialog>
                </Wrapper>
            </div>
            
        );
    }
}

export default withRouter(Login);
