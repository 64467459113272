import React from 'react'
import Grid from '@material-ui/core/Grid';
import LazyLoad from 'react-lazyload';

import 'react-table/react-table.css'
import Card from "@material-ui/core/Card";
import { Wrapper, Image } from "../components";
import Divider from "@material-ui/core/Divider"
import {Typography} from "@material-ui/core";

import GetAppIcon from '@material-ui/icons/GetApp'
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    margin: {
        position: 'sticky',
        top: theme.spacing(1),
        marginLeft: 'auto',
        width: "36px",
        height: "36px",
        background: 'transparent'
    }
}));

export default function MyGallery({photos, filterLogic, history, toggleRaised, raised, type, download, getPhotos, statusAutorizacao}) {
    const classes = useStyles();
    const photosNow = getPhotos(photos, statusAutorizacao);
    const component = (
        <Wrapper>
            <Grid container spacing={4} style={{width: "100%"}}>
            { (photos && photos.photos) ?
                photosNow.map(photo => (
                    <Grid style={{display: "flex", justifyContent: "center"}} item xs={12} sm={6}
                    md={4}>
                        <Card style={{width: "520px", height: "520px", display: "flex", flexDirection: "column", alignItems: "center"}}
                            onMouseOver={() => toggleRaised(photo)}
                            onMouseOut={() => toggleRaised(photo)}
                            raised={raised.find(x => x._id === photo._id).raise}>
                            {type === 'Consumer' ? (
                                <Fab 
                                    onClick={e => download(photo.image.url)}
                                    className={classes.margin} 
                                    variant="round"
                                    disableElevation
                                >
                                    <GetAppIcon />
                                </Fab>
                            ) : ''}
                            <LazyLoad height={200} offset={100} once>
                                <Image onClick={() => history.push('/photo/' + photo._id)} src={photo.compressedImage.url}/>
                            </LazyLoad>
                            <Divider variant="middle" style={{marginBottom: "16px", width: "80%"}}/>
                            <Typography variant="body1">
                                {photo.title ? photo.title : 'Sem Título'}
                            </Typography>
                            <Typography variant="body2">
                                {photo.name ? photo.name : ''}
                            </Typography>
                        </Card>
                    </Grid>)
                )
                : ''
            }
            </Grid>
        </Wrapper>
    )

    return component;
}