import React, { Component } from "react";
import api from "../api";
import "react-table/react-table.css";
import { NavBar, SearchBar, styles, MyGallery } from "../components";
import { isAuthenticated } from "../api/auth";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { searchUrlString, categoryUrlString } from "../utils/urlSearch";

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phtos: [],
      photos: [],
      columns: [],
      isLoading: true,
      loadCounter: false,
      raised: [],
      search: searchUrlString(),
      type: "User",
      navBar: <NavBar />,
      navigation: 0,
      open: null,
      anchorEl: null,
      todas: 0,
      aprovadas: 0,
      aRevisar: 0,
      Reprovadas: 0,
      semAutorizacao: 0,
      comAutorizacao: 0,
      total: 0,
      filter: "Todas as fotos",
      statusAutorizacao: "",
      category: "",
      categories: [],
    };

    this.toggleRaised = this.toggleRaised.bind(this);
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true });

    await api.getMyGallery().then((response) => {
      this.setState({
        photos: response.data.photos,
        todas: response.data.photos.photos.length,
        total: response.data.photos.photos.length,
        raised: response.data.photos.photos.map((photo) => ({
          _id: photo._id,
          raise: false,
        })),
        type: response.data.type,
      });

      let comAutorizacao = 0;
      let semAutorizacao = 0;
      for (const photo of response.data.photos.photos) {
        if (photo.declaration && photo.declaration.url) {
          comAutorizacao = comAutorizacao + 1;
        } else {
          semAutorizacao = semAutorizacao + 1;
        }
      }

      this.setState({
        comAutorizacao: comAutorizacao,
        semAutorizacao: semAutorizacao,
      });

      // response.data.photos.photos.map(photo => (
      //     photo.declaration && photo.declaration.url ? (
      //         this.setState({
      //             comAutorizacao: this.state.comAutorizacao+1})
      //     ) : this.setState({
      //         semAutorizacao: this.state.semAutorizacao+1})
      // ))
    });

    await api.getCategories().then((response) => {
      var cUrl = categoryUrlString();
      var selectedCategory = response.data.categories.find(
        (c) => c.title === cUrl
      );
      this.setState({
        categories: response.data.categories,
        category: selectedCategory ? selectedCategory._id : "",
      });

      this.setState({ isLoading: false });
    });

    this.setState({ isLoading: false });
  };

  toggleRaised = (photo) => {
    const raise = this.state.raised.findIndex((x) => x._id === photo._id);
    let raised = this.state.raised;
    raised[raise].raise = !raised[raise].raise;
    this.setState({ raised: raised });
  };

  toDataURL(url) {
    return fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Origin: window.location.origin,
      },
    })
      .then((response) => {
        console.log(response);
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }

  download = async (url) => {
    const a = document.createElement("a");
    a.href = await this.toDataURL(url);
    a.download = "";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  render() {
    const { photos, total, comAutorizacao, semAutorizacao } = this.state;
    const { classes } = this.props;
    let cAprovadas = 0;
    let cARevisar = 0;
    let cReprovadas = 0;
    let cSemAutorizacao = semAutorizacao;
    let cComAutorizacao = comAutorizacao;
    let cTotal = total;

    const search = (photo, tag) => {
      if (!photo) return false;
      const photoTags = [...photo.tags, photo.title];
      return photoTags
        .map((photoTag) =>
          this.state.category !== ""
            ? photoTag.toLowerCase().includes(tag.toLowerCase()) &&
              photo.category &&
              photo.category.includes(this.state.category)
            : photoTag.toLowerCase().includes(tag.toLowerCase())
        )
        .includes(true);
    };

    const getPhotos = (photos, statusAutorizacao) => {
      //
      cTotal = 0;
      cSemAutorizacao = 0;
      cComAutorizacao = 0;
      let photosNow = [];
      if (photos && photos.photos) {
        photos.photos
          .filter((photo) => filterLogic(photo))
          .map((photo) => photosNow.push(photo));
      }

      photosNow.forEach(
        (photo) => (
          // eslint-disable-next-line
          (cTotal = cTotal + 1),
          photo.status === "Authorized" ? (cAprovadas = cAprovadas + 1) : "",
          photo.status === "Waiting" ? (cARevisar = cARevisar + 1) : "",
          photo.status === "Unauthorized"
            ? (cReprovadas = cReprovadas + 1)
            : "",
          photo.declaration && photo.declaration.url
            ? (cComAutorizacao = cComAutorizacao + 1)
            : (cSemAutorizacao = cSemAutorizacao + 1),
          statusAutorizacao === "com autorizacao"
            ? photo.declaration && photo.declaration.url
              ? null
              : (photosNow = photosNow.filter((p) => p._id !== photo._id))
            : statusAutorizacao === "sem autorizacao"
            ? photo.declaration && photo.declaration.url
              ? (photosNow = photosNow.filter((p) => p._id !== photo._id))
              : null
            : null
        )
      );

      return photosNow;
    };

    const onCapture = (e) => {
      this.setState({
        search: e.target.value,
        statusAutorizacao: "",
        loadCounter: true,
      });
      getPhotos(photos, this.state.statusAutorizacao);
    };

    const onCategory = (e) => {
      this.setState({
        category: e.target.value,
      });
      getPhotos(photos, this.state.statusAutorizacao);
    };

    const filterLogic = (photo) => {
      switch (this.state.navigation) {
        case 1:
          photo = photo.status === "Authorized" ? photo : null;
          return search(photo, this.state.search);
        case 2:
          photo = photo.status === "Waiting" ? photo : null;
          // photo = (photo && photo.declaration && photo.declaration.url) ? photo : null
          return search(photo, this.state.search);
        case 3:
          photo = photo.status === "Unauthorized" ? photo : null;
          return search(photo, this.state.search);
        // case 4:
        //     photo = (photo.declaration && photo.declaration.url) ? null : photo
        //     return (search(photo, this.state.search))
        // case 5:
        //     photo = (photo.declaration && photo.declaration.url) ? photo : null
        //     return (search(photo, this.state.search))
        default:
          return search(photo, this.state.search);
      }
    };

    return (
      <div>
        <NavBar />
        {this.state.loadCounter
          ? getPhotos(photos, this.state.statusAutorizacao) &&
            this.setState({
              aprovadas: cAprovadas,
              aRevisar: cARevisar,
              reprovadas: cReprovadas,
              semAutorizacao: cSemAutorizacao,
              comAutorizacao: cComAutorizacao,
              loadCounter: false,
              total: cTotal,
            })
          : ""}
        <br />
        {isAuthenticated() && window.location.pathname === "/" ? (
          <div className={classes.search}>
            <SearchBar
              search={this.state.search}
              category={this.state.category}
              classes={classes}
              onCapture={onCapture}
              categories={this.state.categories}
              onCategory={onCategory}
            />
            <Menu
              anchorEl={this.state.anchorEl}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              keepMounted
              id={"primary-search-account-menu-mobile"}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.open}
              onClose={() =>
                this.setState({
                  open: null,
                  aprovadas: cAprovadas,
                  aRevisar: cARevisar,
                  reprovadas: cReprovadas,
                  semAutorizacao: cSemAutorizacao,
                  comAutorizacao: cComAutorizacao,
                  total: cTotal,
                })
              }
            >
              <MenuItem
                onClick={() =>
                  this.setState({
                    navigation: 0,
                    filter: "Todas as fotos",
                    statusAutorizacao: "",
                  })
                }
              >
                <Typography variant="body2">Todas</Typography>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  this.setState({
                    navigation: 1,
                    filter: "Fotos aprovadas",
                    statusAutorizacao: "",
                  })
                }
              >
                <Typography variant="body2">Aprovadas</Typography>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  this.setState({
                    navigation: 2,
                    filter: "Fotos a revisar",
                    statusAutorizacao: "",
                  })
                }
              >
                <Typography variant="body2">A Revisar</Typography>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  this.setState({
                    navigation: 3,
                    filter: "Fotos reprovadas",
                    statusAutorizacao: "",
                  })
                }
              >
                <Typography variant="body2">Reprovadas</Typography>
              </MenuItem>
            </Menu>

            {this.state.type === "Admin" ? (
              <IconButton
                aria-label="show more"
                aria-haspopup="true"
                onClick={(event) =>
                  this.setState({ open: true, anchorEl: event.currentTarget })
                }
                color="inherit"
              >
                <FilterListIcon />
              </IconButton>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <br />
        {this.state.type === "Admin" ? (
          <div className={classes.search}>
            {/* Resultados encontrados para: {this.state.search} */}
            {this.state.filter} {this.state.statusAutorizacao}:
          </div>
        ) : null}
        {this.state.type === "Admin" ? (
          <div className={classes.search}>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <Button onClick={() => this.setState({ statusAutorizacao: "" })}>
                Total ({this.state.total})
              </Button>
              <Button
                onClick={() =>
                  this.setState({ statusAutorizacao: "com autorizacao" })
                }
              >
                Com Autorização ({this.state.comAutorizacao})
              </Button>
              <Button
                onClick={() =>
                  this.setState({ statusAutorizacao: "sem autorizacao" })
                }
              >
                Sem Autorização ({this.state.semAutorizacao})
              </Button>
            </ButtonGroup>
          </div>
        ) : null}

        <br />
        <MyGallery
          photos={photos}
          filterLogic={filterLogic}
          history={this.props.history}
          toggleRaised={this.toggleRaised}
          raised={this.state.raised}
          type={this.state.type}
          download={this.download}
          getPhotos={getPhotos}
          statusAutorizacao={this.state.statusAutorizacao}
        />
        {this.state.isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <br />
            <CircularProgress />
          </div>
        ) : (
          ""
        )}
        {!this.state.isLoading && !this.state.photos.photos[0] ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            Nenhuma foto em sua galeria
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Gallery);
